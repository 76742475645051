import React, { useState } from "react";
import StripeCardFrom from "@components/stripe-card-form";
import { Grid, Box, Button } from "@mui/material";
import { useHistory } from "react-router-dom";

import {
  useStripe,
  useElements,
  CardNumberElement,
} from "@stripe/react-stripe-js";

function StripeCardPayment(props) {
  const [name, setName] = useState("");
  const {
    setLoading,
    notifyError: setMessage,
    handleIntent,
    handlePayment,
  } = props;
  const updatePaymentDetails = () => {};
  const history = useHistory();

  const stripe = useStripe();
  const elements = useElements();

  const redirectToSuccess = (paymentId) => {
    handlePayment.onPaymentSuccess();
  };

  const confirm = ({ paymentIntent }) => {
    if (!stripe) {
      return;
    }

    const { client_secret: clientSecret } = paymentIntent;

    if (!clientSecret) {
      return;
    }

    return stripe
      .retrievePaymentIntent(clientSecret)
      .then(({ paymentIntent, ...res }) => {
        switch (paymentIntent.status) {
          case "succeeded":
            redirectToSuccess();
            break;
          case "requires_capture":
            break;

          case "processing":
            setMessage("Your payment is processing.");
            break;
          case "requires_payment_method":
            setMessage("Your payment was not successful, please try again.");
            break;
          default: {
            setMessage("Something went wrong.");
            break;
          }
        }
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    if (!name) {
      return setMessage("Please fill name");
    }

    setLoading(true);

    try {
      const { clientSecret } = await handleIntent();
      const response = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name,
          },
        },
      });

      console.log("stripe response", { response });
      if (response?.error) {
        throw response?.error;
      }

      await confirm(response);
    } catch (error) {
      console.error("issue in payment", { error });
      if (error?.type === "card_error" || error?.type === "validation_error") {
        setMessage(error.message);
      }
      setMessage(error.message);
      handlePayment.onPaymentFail({ message: error.message });
    } finally {
      setLoading(false);
    }
  };

  const handleNameChange = (name) => {
    // dispatch(OrderActions.updatePayeeName(name));
    setName(name);
  };

  return (
    <>
      <Grid xs={12} style={{ marginBottom: 43 }}>
        <StripeCardFrom
          updatePaymentDetails={updatePaymentDetails}
          onNameChange={handleNameChange}
        />
      </Grid>

      <Grid xs={12} item container justifyContent="center">
        <Grid xs={12} item>
          <Button
            size="large"
            fullWidth
            style={{
              fontSize: 20,
              borderRadius: "6px",
              fontWeight: "bold",
              backgroundColor: "#000",
              color: "#fff",
            }}
            onClick={handleSubmit}
            elevation={5}
          >
            <Box
              component="span"
              sx={{ fontSize: "20px", fontWeight: "400", paddingRight: "5px" }}
            >
              Pay
            </Box>
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

StripeCardPayment.propTypes = {};

export default StripeCardPayment;
