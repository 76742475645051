import React, { useEffect } from "react";

import { Grid } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useInView } from "react-intersection-observer";
import { observerConfig } from "./constants";
import { action as UIActions } from "@store/ui/reducer";
import OutletDetail from "@components/outlet-detail";
import MenuToolBar from "@components/menu-tool-bar";
import { actions as menuActions } from "@store/menu/reducer";

function OutletCoverImage() {
  const { image } = useSelector((state) => state.outlet);
  const stickyHeader = useSelector((state) => state.ui?.stickyHeader);
  const { ref, inView } = useInView(observerConfig);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(UIActions.toggleStickyHeader(!inView));
    if (!inView) {
      dispatch(menuActions.updateSelectedTab(0));
    }
  }, [inView]);
  if (!image) {
    return null;
  }

  return (
    <Grid item xs={12} ref={ref}>
      <div
        style={{
          width: "100%",
          height: 163,
          backgroundImage: `url(${encodeURI(image)})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
        }}
      ></div>
      <OutletDetail />
      {!stickyHeader && <MenuToolBar />}
    </Grid>
  );
}
export default OutletCoverImage;
