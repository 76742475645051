import { createVariablesNames } from "@root/store/outlet/constants";

const validItemFilter = (item) => {
  if (!item.visible) {
    return false;
  }
  return item;
};
export const parseJson = (val) => {
  try {
    return JSON.parse(val);
  } catch (e) {
    console.error(e);
    return [];
  }
};
export const menuTransformer = (menu) => {
  const { categories = [] } = menu;
  // const filteredCategory = categories.filter(filterValidCategory);

  const newCategories = [];

  const filteredVisibleCategories = categories.filter(
    (category) => category.visible
  );

  // Iterating filtered visible categories
  filteredVisibleCategories.forEach((category) => {
    const { categories: subCategories } = category;
    const newSubCategories = [];
    const filteredVisibleCategories = subCategories.filter(
      (category) => category.visible
    );
    // Iterating sub-categories
    filteredVisibleCategories.forEach((subCategory) => {
      //Checking items
      const { items } = subCategory;
      const newItems = [];
      // Iterating items
      items.forEach((item) => {
        if (validItemFilter(item)) {
          const transformedAttributes = transformItemAttributes(item);
          newItems.push({ ...item, transformedAttributes });
        }
      });

      // Checking SubCategory
      if (newItems.length) {
        newSubCategories.push({
          ...subCategory,
          items: newItems,
        });
      }
    });
    // Checking for Categories
    if (newSubCategories.length) {
      newCategories.push({
        ...category,
        categories: newSubCategories,
      });
    }
  });

  return { ...menu, categories: newCategories };
};

export const transformItemAttributes = ({ attributes }) => {
  if (!attributes) {
    return [];
  }
  const trueState = ["True", "true", "TRUE", 1, "1"];
  const newAttributes = {
    allergens: [],
    outOfStock: false,
    nutritionSecondary: "",
    nutritionPrimary: "",
    kCal: null,
  };

  for (const { key, value } of attributes) {
    if (key === "allergens") {
      const values = parseJson(value).map((item) => ({
        ...item,
        key: createVariablesNames(item.name),
      }));
      newAttributes.allergens = values;
      newAttributes.allergensKeys = values.map(({ key }) => key);
    }
    if (key === "kCal") {
      newAttributes.kCal = value;
    }
    if (key === "nutritionPrimary") {
      newAttributes.nutritionPrimary = value;
    }
    if (key === "nutritionSecondary") {
      newAttributes.nutritionSecondary = value;
    }
    if (key === "outOfStock" && trueState.includes(value)) {
      newAttributes.outOfStock = true;
    }
  }

  return newAttributes;
};
