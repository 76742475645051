const BasefontTypes = [
  {
    key: "h",
    value: 700,
  },
  {
    key: "subHeading",
    value: 600,
  },
  {
    key: "subTitle",
    value: 500,
  },
  {
    key: "subText",
    value: 400,
  },
];

const baseTypography = [
  {
    fontSize: "2.25rem", // 36px
  },
  {
    fontSize: "1.375rem", //22px
  },
  {
    fontSize: "1.25rem", // 20px
  },
  {
    fontSize: "1rem", // 16px
  },
  {
    fontSize: "0.875rem", // 14px
  },
  {
    fontSize: "0.75rem", // 12px
  },
  {
    fontSize: "0.625rem", //10px
  },
];

const generateTypography = (fontFamily) => {
  const generatedTypography = BasefontTypes.reduce((acc, fontType) => {
    const newTypes = baseTypography.reduce((accTypo, typography, index) => {
      const key = `${fontType.key}${index + 1}`;
      return {
        ...accTypo,
        [key]: { ...typography, fontWeight: fontType.value, fontFamily },
      };
    }, {});

    return { ...acc, ...newTypes };
  }, {});

  return generatedTypography;
};

const Typography = (fontFamily) => ({
  fontSize: 12,
  fontFamily,
  ...generateTypography(fontFamily),
});

export default Typography;
