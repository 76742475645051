import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Grid,
  Button,
  Divider,
  ClickAwayListener,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { multiply, divide } from "mathjs";
import Collapse from "@mui/material/Collapse";
import DoneIcon from "@mui/icons-material/Done";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { IconButton } from "@mui/material";
import { actions as OrderActions } from "@store/order/reducer";

function Tips(props) {
  const dispatch = useDispatch();
  const containerRef = React.useRef(null);

  const [inputActive, setInputActive] = useState(false);
  const [customTips, setCustomTips] = useState();
  const { totalItemsAmount, selectedTipsOption, tipsAmount } = useSelector(
    (state) => state.order
  );
  const { currencySymbol } = useSelector((state) => state.outlet);

  const addTips = (tips, index) => {
    console.log({ tips, index });
    setTimeout(
      () =>
        dispatch(
          OrderActions.updateTipsAmount({
            selectedTipsOption: index,
            tipsAmount: tips,
          })
        ),
      10
    );
  };

  const calculateTips = (percentValue) => {
    return divide(multiply(percentValue, totalItemsAmount), 100);
  };

  const onOtherClick = (index) => {
    setCustomTips(null);
    dispatch(
      OrderActions.updateTipsAmount({
        selectedTipsOption: index,
        tipsAmount: 0.0,
      })
    );
  };

  const addCustomTips = ({ target: { value } }) => {
    //setCustomTips(Number(parseFloat(Math.abs(value))));
    const parsedValue = value.replaceAll(",", ".");
    if (isNaN(parsedValue % 1)) {
      return;
    }
    setCustomTips(parsedValue);
  };

  useEffect(() => {
    if (tipsAmount) {
      setCustomTips(tipsAmount);
    }
  }, []);

  const handleClickAway = () => {
    if (inputActive) {
      setInputActive(false);
    }
    saveCustomTips();
  };

  const saveCustomTips = () => {
    if (!isNaN(customTips)) {
      addTips(Number(parseFloat(Math.abs(customTips))), 4);
    }
  };

  return (
    <>
      <Grid container justifyContent="space-between" rowSpacing={2}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subTitle4">Add a tip</Typography>
        </Grid>
        <Grid item>
          <Button
            variant={selectedTipsOption === 0 ? "contained" : "outlined"}
            onClick={() => addTips(0, 0)}
          >
            No Tips
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant={selectedTipsOption === 1 ? "contained" : "outlined"}
            onClick={() => addTips(calculateTips(10), 1)}
          >
            10%
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant={selectedTipsOption === 2 ? "contained" : "outlined"}
            onClick={() => addTips(calculateTips(15), 2)}
          >
            15%
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant={selectedTipsOption === 3 ? "contained" : "outlined"}
            onClick={() => addTips(calculateTips(20), 3)}
          >
            20%
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant={selectedTipsOption === 4 ? "contained" : "outlined"}
            onClick={() => onOtherClick(4)}
          >
            Other
          </Button>
        </Grid>
        <Collapse
          direction="up"
          in={selectedTipsOption === 4}
          container={containerRef.current}
          sx={{ width: "100%" }}
          mountOnEnter
          unmountOnExit
        >
          <ClickAwayListener
            onClickAway={handleClickAway}
            mouseEvent="onMouseUp"
          >
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={customTips}
              fullWidth
              variant="outlined"
              placeholder="0.00"
              size="small"
              style={{ textAlign: "center" }}
              onChange={addCustomTips}
              onFocus={() => setInputActive(true)}
              inputProps={{ inputMode: "decimal" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    Other Tips : {currencySymbol}
                  </InputAdornment>
                ),
                endAdornment: inputActive ? (
                  <InputAdornment position="end" onClick={saveCustomTips}>
                    <IconButton aria-label="delete">
                      <DoneIcon />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
            />
          </ClickAwayListener>
        </Collapse>

        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
    </>
  );
}

Tips.propTypes = {};

export default Tips;
