import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Typography, Grid, Box } from "@mui/material";
import Currency from "@components/currency";
import { actions as outletActions } from "@store/outlet/reducer";

function AmountOverview(props) {
  const { tipsAmount } = useSelector((state) => state.order);
  const { orderTotals = {} } = useSelector((state) => state.checkout);
  const { surcharges = [], discounts = [] } = orderTotals;
  const { attributes, removeSurcharges } = useSelector((state) => state.outlet);

  const dispatch = useDispatch();
  const onRemoveSurcharges = () => {
    dispatch(outletActions.enableRemoveSurcharges(true));
  };

  return (
    <Grid container rowSpacing={1}>
      <Grid item xs={8}>
        <Typography variant="subTitle5" sx={{ fontWeight: 500 }}>
          Subtotal
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="subTitle5" align="right" sx={{ fontWeight: 500 }}>
          <Currency value={orderTotals?.subtotal} />
        </Typography>
      </Grid>
      {/* Surcharges list */}
      {surcharges.map((surcharge) => (
        <>
          <Grid item xs={8}>
            <Typography variant="subTitle5">{surcharge?.name}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subTitle5" align="right">
              <Currency value={surcharge?.amount} />
            </Typography>
          </Grid>
        </>
      ))}
      {attributes.allowSurchargeRemoval && !removeSurcharges && (
        <Grid item xs={12}>
          <Typography variant="h4" onClick={onRemoveSurcharges} color="#A6A6A6">
            <Box component="span" sx={{ cursor: "pointer" }}>
              Remove
            </Box>
          </Typography>
        </Grid>
      )}
      {/* {discounts.map((discount) => (
        <>
          <Grid item xs={8}>
            <Typography variant="subTitle5">{discount?.name}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subTitle5" align="right">
              <Currency value={discount?.amount} />
            </Typography>
          </Grid>
        </>
      ))} */}
      {orderTotals?.totalDiscounts > 0 && (
        <>
          <Grid item xs={8}>
            <Typography variant="subTitle5">Discount</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subTitle5" align="right">
              -<Currency value={orderTotals.totalDiscounts} />
            </Typography>
          </Grid>
        </>
      )}
      {Boolean(tipsAmount) && (
        <Grid item xs={8}>
          <Typography variant="subTitle5">Tip</Typography>
        </Grid>
      )}
      {Boolean(tipsAmount) && (
        <Grid item xs={4}>
          <Typography variant="subTitle5" align="right">
            <Currency value={tipsAmount} />
          </Typography>
        </Grid>
      )}
      {Boolean(orderTotals?.totalTaxes) && (
        <Grid item xs={8}>
          <Typography variant="subTitle5">Taxes</Typography>
        </Grid>
      )}
      {Boolean(orderTotals?.totalTaxes) && (
        <Grid item xs={4}>
          <Typography variant="subTitle5" align="right">
            <Currency value={orderTotals?.totalTaxes} />
          </Typography>
        </Grid>
      )}
      <Grid item xs={8}>
        <Typography variant="subTitle4" sx={{ fontWeight: 500 }}>
          Total
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="subTitle4" align="right" sx={{ fontWeight: 500 }}>
          <Currency value={orderTotals?.totalPrice + tipsAmount} />
        </Typography>
      </Grid>
    </Grid>
  );
}

AmountOverview.propTypes = {};

export default AmountOverview;
