import { Card, CardContent, Divider, Grid } from "@mui/material";

export const ModifiedGrid = ({ title, children }) => (
  <Grid item xs={12} sx={{ padding: "0 8px 0 8px" }}>
    <Card elevation={0} sx={{ borderRadius: "24px" }}>
      <CardContent sx={{ padding: "12px 8px" }}>
        <Grid container rowGap={1}>
          <Grid item xs={12} container>
            {title}
            <Divider sx={{ width: "100%" }} />
          </Grid>
          {children}
        </Grid>
      </CardContent>
    </Card>
  </Grid>
);
