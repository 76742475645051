import TenantService from "@services/TenantService";
import { actions as tenantActions } from "@store/tenant/reducer";

const { startLoading, updateError, endLoading, updateTenant } = tenantActions;

export const getTenant = (payload) => (dispatch) => {
  dispatch(startLoading());
  return TenantService.getTenant(payload)
    .then((res) => {
      dispatch(updateTenant(res));
      return res;
    })
    .catch((error) => {
      console.error(error);
      dispatch(updateError(error));
      throw error;
    })
    .finally(() => {
      dispatch(endLoading());
    });
};
