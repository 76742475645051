import React from "react";
import PropTypes from "prop-types";
import OutletList from "@components/outlet-list";

function Outlets(props) {
  return <OutletList />;
}

Outlets.propTypes = {};

export default Outlets;
