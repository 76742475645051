import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Stripe from "@containers/stripe/Stripe";
import Adyen from "@containers/adyen";

function PaymentGatewaySelector(props) {
  const { providers } = useSelector((state) => state.checkout);
  const providerNames = providers.map(({ name }) => name);
  const [providerName] = providerNames;

  if (providerName === "Stripe") {
    return <Stripe {...props} handlePayment={{ ...props }} />;
  }
  return <Adyen {...props} />;
}

PaymentGatewaySelector.propTypes = {};

export default PaymentGatewaySelector;
