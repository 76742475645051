import { ElementsConsumer, PaymentElement } from "@stripe/react-stripe-js";

const CheckoutForm = ({ stripe, elements, props }) => {
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { clientSecret } = await props.handleIntent({
      amountInSmallestUnit: 200,
      currency: "GBP",
    });

    console.log({ clientSecret });

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "https://example.com/order/123/complete",
      },
    });

    if (result.error) {
      console.log(result.error.message);
    } else {
      // Handle successful payment
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button disabled={!stripe}>Submit</button>
    </form>
  );
};

const InjectedCheckoutForm = (props) => {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm stripe={stripe} elements={elements} {...props} />
      )}
    </ElementsConsumer>
  );
};

export default InjectedCheckoutForm;
