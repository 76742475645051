import MenuService from "@services/MenuService";
import { actions as menuActions } from "@store/menu/reducer";
import { action as UIActions } from "@store/ui/reducer";
import filter from "lodash.filter";

const { startLoading, updateMenu, updateError, endLoading } = menuActions;

export const getMenu = (payload) => (dispatch) => {
  dispatch(startLoading());
  return MenuService.getMenu(payload)
    .then((res) => {
      dispatch(updateMenu(removeEmpty(res)));
      //dispatch(updateMenu(res));
      return res;
    })
    .catch((error) => {
      console.error(error);
      dispatch(updateError(error));
      throw error;
    })
    .finally(() => {
      dispatch(endLoading());
      dispatch(UIActions.endMenuPageLoading());
    });
};

export const filterMenu = (payload) => (dispatch) => {
  console.log({ payload });
  dispatch(menuActions.updateSelectedMenu(payload));
};

const removeEmpty = (payload) => {
  // This method removes categories and sub categories if they don't have any item in it

  let newCategory = [];
  const { categories } = payload;

  for (const category of categories) {
    if (category?.categories && category?.categories.length) {
      const newSubCategory = [];
      for (const subCategory of category.categories) {
        if (subCategory?.items && subCategory?.items.length) {
          newSubCategory.push(subCategory);
        }
      }
      if (newSubCategory.length) {
        newCategory.push({ ...category, categories: newSubCategory });
      }
    }
  }
  return { ...payload, categories: newCategory };
};
