import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Grid, Box, Typography, Divider } from "@mui/material";
import Currency from "@components/currency";
import "./style.css";

function ConfirmedOrderSummary({ order, outlet }) {
  const { currencySymbol } = outlet;
  const { items = {} } = order;
  // const { items = {} } = summary;
  const itemSelectedForOrder = Object.values(items);
  return (
    <Grid container spacing={2} className="confirmed-order-summary">
      <Grid item xs={12}>
        <Typography variant="subText5" sx={{ fontSize: 18, color: "#7c7c7c" }}>
          Order Summary
        </Typography>
      </Grid>
      {itemSelectedForOrder.map((item) => (
        <>
          <Grid item xs={1}>
            <Typography align="center" variant="subText5">
              {item.quantity}
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="subTitle5" sx={{ fontWeight: 500 }}>
              {item.name}
            </Typography>
            {Boolean(item.selectedModifiers.length) &&
              item.selectedModifiers.map((modifier) => (
                <Typography variant="subText5">+ {modifier.name}</Typography>
              ))}
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subText5" align="right">
              <Currency value={item.totalPrice} symbol={currencySymbol} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </>
      ))}
    </Grid>
  );
}

ConfirmedOrderSummary.propTypes = {};

export default ConfirmedOrderSummary;
