import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import PropTypes from "prop-types";
import ICON_MAP from "./svg";

const DEFAULT_ICON = "group";

const Icon = ({ variant, ...props }) => {
  //   const color = nativeColor ? nativeColor : DEFAULT_COLOR
  const Icon = ICON_MAP[variant] ? ICON_MAP[variant] : ICON_MAP[DEFAULT_ICON];
  return (
    <SvgIcon
      {...props}
      component={Icon}
      style={{ fill: "transparent", ...props.style }}
    />
  );
};

Icon.propTypes = {
  variant: PropTypes.string,
  nativeColor: PropTypes.string,
};

export default Icon;
