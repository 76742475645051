import { createTheme } from "@mui/material/styles";
import Typography from "./typography";
import ComponentsOverrides from "./overrides";

const fontFamily = ["Manrope"].join(",");

const theme = createTheme({
  fontFamily,
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#8E8E8E",
      text: "#8E8E8E",
    },
    success: {
      main: "#34A853",
    },
  },
  typography: Typography(fontFamily),
});

theme.components = ComponentsOverrides(theme);

export default theme;
