import APIService from "./APIService";

export default class PaymentService extends APIService {
  static getAdyenPaymentSession(params) {
    return this.post(`/PaymentSession`, {
      body: JSON.stringify(params),
    })
      .then(this.getResponseData)
      .catch(this.getResponseError);
  }
  static getPaymentMethod(params) {
    const queryParameter = this.buildQuery(params);
    return this.get(`/PaymentMethodsV2${queryParameter}`)
      .then(this.getResponseData)
      .catch(this.getResponseError);
  }
  static getOrderTotals(params) {
    return this.post(`/OrderTotals`, {
      body: JSON.stringify(params),
    })
      .then(this.getResponseData)
      .catch(this.getResponseError);
  }
  static tigerTMSPayment(params) {
    return this.post(`/PaymentV2`, {
      body: JSON.stringify({ ...params, providerName: "TigerTMS Room Charge" }),
    })
      .then(this.getResponseData)
      .catch(this.getResponseError);
  }
  static cashPayment(params) {
    return this.post(`/PaymentV2`, {
      body: JSON.stringify({ ...params, providerName: "Cash" }),
    })
      .then(this.getResponseData)
      .catch(this.getResponseError);
  }
  static adyenPayment(params) {
    return this.post(`/PaymentV2`, {
      body: JSON.stringify({ ...params, providerName: "Adyen" }),
    })
      .then(this.getResponseData)
      .catch(this.getResponseError);
  }
  static createStripeIntent(params) {
    return this.post(`/Stripe/CreateStripeIntent`, {
      body: JSON.stringify({ ...params }),
    })
      .then(this.getResponseData)
      .catch(this.getResponseError);
  }
}
