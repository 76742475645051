import React from "react";
import PropTypes from "prop-types";
import { Button, Grid, Typography } from "@mui/material";

function ErrorPage({ message, ...props }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} container justifyContent="center">
        <img
          src="/assets/images/404.svg"
          alt="404-page"
          style={{ paddingTop: "100px", paddingBottom: "50px" }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h1"
          align="center"
          sx={{ fontSize: 40, fontWeight: "bold" }}
        >
          Oops!
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h1" align="center" sx={{ fontSize: 20 }}>
          {message}
        </Typography>
      </Grid>
      {props.onClickActionButton && (
        <Grid item xs={12}>
          <Typography variant="h1" align="center" sx={{ fontSize: 20 }}>
            <Button
              onClick={props.onClickActionButton}
              variant="contained"
              sx={{ backgroundColor: "#000 !important" }}
            >
              {props.actionButtonLabel}
            </Button>
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}

ErrorPage.propTypes = {
  message: PropTypes.string,
  onClickActionButton: PropTypes.any,
};

ErrorPage.defaultProps = {
  message: "The page you are looking for is not available!",
  onClickActionButton: null,
};

export default ErrorPage;
