import { createSlice, current } from "@reduxjs/toolkit";
import { menuTransformer } from "@root/util/menuTransformer";
import { filterMenu } from "./constants";

const initialState = {
  isLoading: true,
  menu: null,
  error: null,
  selectedTab: 0,
  selectedItem: null,
  selectedMenu: [],
  selectedFilters: [],
  selectedSubCategoryTab: 0,
  filters: {
    searchString: "",
    allergens: [],
  },
};

const menu = createSlice({
  name: "menu",
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    startLoading: (state, { payload }) => ({
      ...state,
      isLoading: true,
      error: null,
    }),
    endLoading: (state, { payload }) => ({ ...state, isLoading: false }),
    updateMenu: (state, { payload }) => ({
      ...state,
      menu: menuTransformer(payload),
      selectedMenu: menuTransformer(payload),
    }),
    updateSelectedMenu: (state, { payload }) => {
      const { filters } = current(state);
      let updateFilters = {
        searchString: filters?.searchString,
        allergens: filters?.allergens,
      };
      if (payload?.searchString !== undefined) {
        updateFilters = {
          ...updateFilters,
          searchString: payload?.searchString,
        };
      }

      if (payload?.allergens !== undefined) {
        updateFilters = { ...updateFilters, allergens: payload?.allergens };
      }

      return {
        ...state,
        filters: updateFilters,
        selectedFilters: updateFilters?.allergens.map((item) => item.key),
        selectedMenu: filterMenu(updateFilters, current(state)),
      };
    },
    updateSelectedItem: (state, { payload }) => ({
      ...state,
      selectedItem: payload,
    }),
    updateError: (state, { payload }) => ({ ...state, error: payload }),
    updateSelectedTab: (state, { payload }) => ({
      ...state,
      selectedTab: payload,
    }),
    updateSelectedSubCategoryTab: (state, { payload }) => ({
      ...state,
      selectedSubCategoryTab: payload,
    }),
  },
});

export const actions = menu.actions;

export default menu;
