import React, { useEffect } from "react";
import PropTypes from "prop-types";
import PaymentService from "@services/PaymentService";
import { action as UIActions } from "@store/ui/reducer";
import { useDispatch } from "react-redux";
import { Grid, Typography } from "@mui/material";

let ORDER_ID = null;
function CashPayment({ totalPayment, startOrder, ...props }) {
  const onSave = async (formData) => {
    console.log("Into to cash payment method");
    try {
      props.setLoading(true);
      const { order } = await startOrder();
      const { orderId } = order;
      ORDER_ID = orderId;
      const CashPaymentModel = {
        CashPaymentModel: {
          value: 0,
        },
      };
      console.log("Calling to cash payment Api");
      const { success } = await PaymentService.cashPayment({
        ...CashPaymentModel,
        orderId,
      });
      console.log("Finished Cash API");

      if (success === true) {
        console.log("Into Cash API sucess");
        await props.onPaymentSuccess({
          orderId: ORDER_ID,
        });
      } else {
        throw new Error("Unable to make payment.Please try again later");
      }
      console.log("Outside Cash API sucess");
    } catch (e) {
      console.log("Into Cash API error ", e);
      if (e?.status?.error) {
        props.onPaymentFail({
          message: e.status.userMessage,
          orderId: ORDER_ID,
        });
        props.goToMenu();
      }
    } finally {
      props.setLoading(false);
    }
  };

  useEffect(() => {
    onSave();
  }, []);

  return "";
}

CashPayment.propTypes = {};

export default CashPayment;
