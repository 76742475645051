import { action as uiActions } from "./ui/reducer";
import { actions as outletActions } from "./outlet/reducer";
import { actions as sessionActions } from "./session/reducer";
import { actions as menuActions } from "./menu/reducer";
import { actions as orderActions } from "./order/reducer";
import { actions as checkOutActions } from "./checkout/reducer";
import { actions as paymentActions } from "./payment/reducer";
import { actions as tenantActions } from "./tenant/reducer";

export const reset = (payload) => (dispatch) => {
  dispatch(uiActions.reset());
  dispatch(outletActions.reset());
  // dispatch(sessionActions.reset());
  dispatch(menuActions.reset());
  dispatch(orderActions.reset(payload));
  dispatch(checkOutActions.reset());
  dispatch(paymentActions.reset());
  dispatch(tenantActions.reset());
};
