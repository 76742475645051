import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, Checkbox } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { actions as OrderActions } from "@store/order/reducer";

function Agreement({ value, ...props }) {
  const dispatch = useDispatch();
  const { updateAgree } = OrderActions;
  //dummy
  const { agree } = useSelector((state) => state.order);

  const onChange = ({ target: { checked } }) => {
    dispatch(updateAgree(checked));
    props.onChange({ target: { value: checked, name: props.name } });
  };
  return (
    <Grid container columnSpacing={1} rowGap={2}>
      <Grid item xs={12}>
        <Typography variant="subTitle5" sx={{ color: "#7c7c7c" }}>
          Age restricted items (including alcohol and tobacco) are not for sale
          to anyone under the age of 18. You may need to provide a valid ID upon
          picking up or recieving your order.
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography align="left">
          <Checkbox onChange={onChange} checked={value} sx={{ p: 0 }} />{" "}
        </Typography>
      </Grid>
      <Grid item xs={11}>
        <Typography variant="subTitle5" sx={{ color: "#7c7c7c" }}>
          I confirm that I am over 18 years of age. I understand that if I
          cannot show valid identification to prove this then I may be refused
          service.
        </Typography>
      </Grid>
    </Grid>
  );
}

Agreement.propTypes = {};

export default Agreement;
