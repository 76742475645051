import APIService from "./APIService";

export default class SessionService extends APIService {
  static createSession(params) {
    return this.post(`/session`, {
      body: JSON.stringify(params),
    })
      .then(this.getResponseData)
      .catch(this.getResponseError);
  }
}
