import React, { useRef, useImperativeHandle } from "react";

const StripeInput = React.forwardRef((props, ref) => {
  const { component: Component, ...other } = props;
  const elementRef = ref;
  useImperativeHandle(ref, () => ({
    focus: () => elementRef.current.focus,
  }));

  return (
    <Component
      onReady={(element) => (elementRef.current = element)}
      {...props}
    />
  );
});
export default StripeInput;
