import React from "react";
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useParams,
} from "react-router-dom";

import LogoLayout from "@root/layout/LogoLayout";

import RouteWrapper from "./RouteWrapper";
import Menu from "@pages/menu";
import ViewOrder from "@pages/view-order";
import Payment from "@pages/payment";
import RoomCharges from "@pages/room-charges";
import OrderConfirmation from "@pages/order-confirmation";
import ErrorPage from "@pages/error-page";
import OutletsPage from "@pages/outlets";
import CashPayment from "@pages/cash-payment";
import CacheControl from "@components/cache-control";
import Stripe from "@containers/stripe";
// Retry build

const RedirectToMenu = () => {
  const history = useHistory();
  const { outletId, orderType, locationId } = useParams();
  React.useEffect(() => {
    history.push(
      `/${outletId}/${orderType}${locationId ? `/${locationId}` : ""}`
    );
  }, []);
  return null;
};

function Layout(props) {
  return (
    <Switch>
      <RouteWrapper path="/tenant/:tenantId" component={OutletsPage} exact />
      <RouteWrapper
        path="/outlet/:outletId/:orderType/:locationId?"
        component={RedirectToMenu}
        exact
      />

      <RouteWrapper path="/stripe-poc" component={Stripe} exact />
      <RouteWrapper
        path="/view-order/outlet/:outletId/order-type/:orderType"
        component={ViewOrder}
        exact
      />
      <RouteWrapper
        path="/view-order/outlet/:outletId/order-type/:orderType/location/:locationId"
        component={ViewOrder}
        exact
      />

      <RouteWrapper
        path="/payment/outlet/:outletId/order-type/:orderType"
        component={Payment}
        exact
      />
      <RouteWrapper
        path="/room-charges/outlet/:outletId/order-type/:orderType"
        component={RoomCharges}
        exact
      />
      <RouteWrapper
        path="/cash-payment/outlet/:outletId/order-type/:orderType"
        component={CashPayment}
        exact
      />
      <RouteWrapper
        path="/order-confirmation/outlet/:outletId/order-type/:orderType"
        exact
      >
        <CacheControl>
          <OrderConfirmation />
        </CacheControl>
      </RouteWrapper>
      <RouteWrapper
        path="/:outletId/:orderType/:locationId?"
        component={Menu}
        exact
      />
      <RouteWrapper path="/not-found" component={ErrorPage} exact />
      <Redirect to="/not-found" />
    </Switch>
  );
}

export default Layout;
