import APIService from "./APIService";

export default class FeedbackService extends APIService {
  static email(params) {
    return this.post(`/EmailConfirmation`, {
      body: JSON.stringify(params),
    })
      .then(this.getResponseData)
      .catch(this.getResponseError);
  }
}
