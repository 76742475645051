import APIService from "./APIService";

export default class CheckoutService extends APIService {
  static getCheckoutOptions(params) {
    const queryParameter = this.buildQuery(params);
    return this.get(`/CheckoutOptions${queryParameter}`)
      .then(this.getResponseData)
      .catch(this.getResponseError);
  }
  static getPaymentMethod(params) {
    const queryParameter = this.buildQuery(params);
    return this.get(`/PaymentMethodsV2${queryParameter}`)
      .then(this.getResponseData)
      .catch(this.getResponseError);
  }
  static getOrderTotals(params) {
    return this.post(`/OrderTotals`, {
      body: JSON.stringify(params),
    })
      .then(this.getResponseData)
      .catch(this.getResponseError);
  }
  static setOrderStatus(params) {
    return this.post(`/OrderStatus`, {
      body: JSON.stringify(params),
    })
      .then(this.getResponseData)
      .catch(this.getResponseError);
  }
  static placeOrder(params) {
    return this.post(`/order`, {
      body: JSON.stringify(params),
    })
      .then(this.getResponseData)
      .catch(this.getResponseError);
  }
}
