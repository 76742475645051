import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import AdyenCheckout from "@adyen/adyen-web";
import { Typography, Grid, Box } from "@mui/material";
import Loading from "@components/loading";
import QueryString from "query-string";

import "@adyen/adyen-web/dist/adyen.css";
import "./style.css";

function AdyenElements({
  totalPayment,
  totalAmount,
  notifyError,
  startOrder,
  adyenConfiguration,
  ...props
}) {
  // Handling of redirect condition
  // https://docs.adyen.com/online-payments/web-components#handleredirectresult
  const queryParams = QueryString.parse(window.location.search);
  const { sessionId, redirectResult } = queryParams;

  // End of redirect condition

  // Hooks initialization
  const paymentContainer = useRef(null);
  const outlet = useSelector((state) => state.outlet);
  // End  Hooks initialization

  const { onPaymentFail, onPaymentSuccess, setLoading } = props;

  const onPaymentComplete = (...args) => {
    const { resultCode } = args[0];
    if (resultCode === "Refused") {
      return onPaymentFail(...args).then((res) => props.goToPreviousPage());
    }
    return onPaymentSuccess(...args);
  };

  const getAdyenCheckoutConfiguration = (sessionId) => {
    const { id, sessionData, environment } = adyenConfiguration;
    const configuration = {
      environment, // Change to 'live' for the live environment.
      clientKey: `${process.env.ADYEN_CLIENT_KEY}`, // Public key used for client-side authentication: https://docs.adyen.com/development-resources/client-side-authentication
      analytics: {
        enabled: false, // Set to false to not send analytics data to Adyen.
      },
      session: {
        id: sessionId || id, // Unique identifier for the payment session.
        sessionData, // The payment session data.
      },
      beforeSubmit: (data, component, { resolve }) => {
        // setLoading(true);
        resolve(data);
        // console.log({ data, resolve });
      },

      onPaymentCompleted: onPaymentComplete,
      // onError: onPaymentFail,
      // Any payment method specific configuration. Find the configuration specific to each payment method:  https://docs.adyen.com/payment-methods
      // For example, this is 3D Secure configuration for cards:
      paymentMethodsConfiguration: {
        card: {
          hasHolderName: true,
          holderNameRequired: true,
          billingAddressRequired: false,
        },
      },
    };

    return configuration;
  };

  const getAdyenCheckout = async (val) => {
    const configuration = getAdyenCheckoutConfiguration();

    if (Object.keys(adyenConfiguration).length) {
      const checkout = await AdyenCheckout(configuration);
      if (paymentContainer.current) {
        checkout.create("dropin").mount(paymentContainer.current);
      }
    }
  };

  const handleRedirect = async () => {
    if (redirectResult) {
      setLoading(true);
      const checkout = await AdyenCheckout(
        getAdyenCheckoutConfiguration(sessionId)
      );
      // Submit the redirectResult value you extracted from the returnUrl.
      checkout.submitDetails({ details: { redirectResult: redirectResult } });
    }
  };

  useEffect(() => getAdyenCheckout(), []);
  useEffect(() => handleRedirect(), [redirectResult]);

  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <Typography variant="subTitle4">Please enter card details</Typography>
      </Grid>
      <Grid item xs={12}>
        <Box position="relative">
          <Box position="absolute" height="200px" width="100%" right="0px">
            <Loading height="100%" />
          </Box>
          <Box sx={{ zIndex: 9999, position: "relative", background: "#fff" }}>
            <div id="dropin-container" ref={paymentContainer}></div>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

AdyenElements.propTypes = {};

export default AdyenElements;
