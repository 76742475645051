// ==============================|| OVERRIDES - MuiTypography ||============================== //

export default function MuiTypography({ palette, ...theme }) {
  return {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          subHeading1: "p",
          subHeading2: "p",
          subHeading3: "p",
          subHeading4: "p",
          subHeading5: "p",
          subHeading6: "p",
          subText1: "p",
          subText2: "p",
          subText3: "p",
          subText4: "p",
          subText5: "p",
          subText6: "p",
          subText7: "p",

          subTitle1: "p",
          subTitle2: "p",
          subTitle3: "p",
          subTitle4: "p",
          subTitle5: "p",
          subTitle6: "p",

          caption1: "p",
          caption2: "p",
          caption3: "p",
          caption4: "p",
          caption5: "p",

          body1: "p",
          body2: "p",
          body3: "p",
          body4: "p",

          notetext: "p",
          smalltext: "p",
        },
      },
      styleOverrides: {
        color: palette.text.primary,
      },
    },
  };
}
