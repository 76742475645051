import React, { useState } from "react";
import "./styles.css";
import { Box } from "@mui/material";

const overFlowStyle = ({
  maxHeight,
  isContentOverflowing,
  priceIsAvailable,
}) => {
  const isOverflow = { maxHeight, overflow: "hidden" };
  let general = {
    position: "relative",
  };
  if (!priceIsAvailable) {
    general = { ...general, height: "fit-content" };
  }
  if (isContentOverflowing) {
    general = { ...general, ...isOverflow };
  }
  return general;
};

const DynamicResizeHandler = ({ children, maxHeight, priceIsAvailable }) => {
  const ref = React.useRef(null);
  const [height, setHeight] = React.useState(0);
  const [isContentOverflowing, setIsContentOverflowing] = useState(false);

  React.useEffect(() => {
    const height = ref.current.offsetHeight;
    setIsContentOverflowing(height > maxHeight);
  }, []);

  return (
    <Box
      ref={ref}
      sx={overFlowStyle({ maxHeight, isContentOverflowing, priceIsAvailable })}
    >
      {children}
      {isContentOverflowing ? (
        <Box
          component="span"
          sx={{
            position: "absolute",
            fontSize: "0.875rem",
            fontWeight: 400,
            fontFamily: "Manrope",
            color: "#7C7C7C",
            backgroundColor: "#fff",
            bottom: 3,
            height: "21px",
            right: 0,
            paddingLeft: "6px",
          }}
          onClick={(e) => {
            e.preventDefault();
            setIsContentOverflowing(false);
          }}
        >
          ... see more
        </Box>
      ) : null}
    </Box>
  );
};

export default DynamicResizeHandler;
