import { detectDevice } from "@utils/device";

export const getDevice = () => {
  const detectedDevice = detectDevice();
  let device = "google";
  if (detectedDevice === "iOS") {
    device = "apple";
  }
  return device;
};

export const buttons = {
  isGooglePayInActive: "/assets/images/button/active-google-pay.svg",
  isApplePayInActive: "/assets/images/button/active-apple-pay.svg",
};
