import React from "react";
import PropTypes from "prop-types";
import {
  Typography,
  ListItemText,
  ListItemAvatar,
  ListItemButton,
  Checkbox,
  ListItem,
  List,
  Box,
} from "@mui/material";

function FilterAllergenForm({ handleChange, filterOptions, ...props }) {
  const { values } = props;

  return (
    <List
      dense
      sx={{
        bgcolor: "background.paper",
      }}
    >
      {filterOptions.map((option) => {
        const labelId = `checkbox-list-secondary-label-${option.key}`;
        return (
          <ListItem
            key={option.key}
            secondaryAction={
              <Checkbox
                edge="end"
                inputProps={{ "aria-labelledby": labelId }}
                onChange={handleChange}
                value={option.key}
                checked={values.filterAllergen.includes(option.key)}
                name="filterAllergen"
                sx={{ pr: 0 }}
              />
            }
            sx={{ width: "100%" }}
            disablePadding
          >
            <ListItemButton sx={{ pl: 0, pr: 0 }}>
              <ListItemAvatar
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  paddingTop: "8px",
                  minWidth: "30px",
                }}
              >
                <Box
                  sx={{
                    fontSize: "16px",
                    fontWeight: 700,
                    color: option.color,
                    textAlign: "center",
                  }}
                >
                  {option.abbr}
                </Box>
              </ListItemAvatar>
              <ListItemText
                id={option.key}
                primary={
                  <Typography variant="subTitle4">{option.label}</Typography>
                }
              />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
}

FilterAllergenForm.propTypes = {};

export default FilterAllergenForm;
