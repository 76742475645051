import React from "react";
import PropTypes from "prop-types";
import {
  Drawer,
  Grid,
  Button,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./style.css";

function PaymentFailedDialog({ onClose, onRetry }) {
  return (
    <Drawer
      anchor="bottom"
      open
      onClose={onClose}
      className="paymentFailedDialog"
    >
      <Box p={5}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          columnSpacing={2}
          rowSpacing={3}
        >
          <Grid item xs={12}>
            <Typography variant="h2" align="center" sx={{ fontSize: "20px" }}>
              Payment failed
            </Typography>
            <Typography variant="h2" align="center" sx={{ fontSize: "20px" }}>
              Would you like to try again?
            </Typography>
          </Grid>

          <Grid item xs={5} onClick={onRetry}>
            <Button
              variant="contained"
              className="room-charges-button"
              fullWidth
            >
              Yes
            </Button>
          </Grid>

          <Grid item xs={5} onClick={onClose}>
            <Button variant="text" className="room-charges-button" fullWidth>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
}

PaymentFailedDialog.propTypes = {
  onClose: PropTypes.func,
  onRetry: PropTypes.func,
};

PaymentFailedDialog.defaultProps = {
  onClose: () => {},
  onRetry: () => {},
};

export default PaymentFailedDialog;
