import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Drawer,
  Grid,
  Button,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as VisaCardIcon } from "./Visa.svg";
import { ReactComponent as MasterCardIcon } from "./MasterCard.svg";
import { ReactComponent as AmexCardIcon } from "./Amex.svg";

import { useSelector, useDispatch } from "react-redux";
import { actions as CheckoutActions } from "@store/checkout/reducer";
import { useParams, useHistory } from "react-router-dom";
import "./style.css";
import { PaymentActions } from "@root/store/payment/reducer";

const paymentGateways = ["Stripe", "Adyen"];

function PaymentMethodOptions(props) {
  const dispatch = useDispatch();
  const { continueToPay, providers } = useSelector((state) => state.checkout);
  const history = useHistory();
  const providerNames = providers.map(({ name }) => name);
  const { outletId, orderType } = useParams();

  const urlParams = `/outlet/${outletId}/order-type/${orderType}`;
  const moveToPayment = () => history.push(`/payment${urlParams}`);
  const moveToRoomCharges = () => history.push(`/room-charges${urlParams}`);
  const moveToCashPayment = () => history.push(`/cash-payment${urlParams}`);
  const moveToStripePayment = () => history.push("payment/stripe");

  if (providerNames.length === 1 && continueToPay) {
    console.log("CLICKED ON CONTINUE");
    const [providerName] = providerNames;
    if (paymentGateways.includes(providerName)) {
      console.log("Moving to payment gateway");
      moveToPayment();
    }

    if (providerName === "TigerTMS Room Charge") {
      moveToRoomCharges();
    }

    if (providerName === "Cash") {
      console.log("Moving to cash payment method");
      moveToCashPayment();
    }
    dispatch(CheckoutActions.updateContinueToPay(false));
  }

  useEffect(() => dispatch(PaymentActions.updateAdyenConfiguration({})), []);

  return (
    <Drawer
      anchor="bottom"
      open={continueToPay}
      onClose={() => dispatch(CheckoutActions.updateContinueToPay(false))}
      className="providers"
      sx={{
        ".MuiDrawer-paper": {
          position: "absolute", //imp //drawer width
          maxWidth: "580px",
          margin: "auto",
        },
      }}
    >
      <Box pb={1} pt={2} pl={2} position="relative">
        <Typography align="right">
          {" "}
          <IconButton
            aria-label="close"
            onClick={() => dispatch(CheckoutActions.updateContinueToPay(false))}
          >
            <CloseIcon />
          </IconButton>
        </Typography>
        <Typography variant="subTitle3" align="center">
          Select a payment method.
        </Typography>
        <Box position="absolute" top={7} right={1}></Box>
      </Box>
      <Box pt={4} pb={4}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          rowGap={2}
          columnGap={1}
        >
          {providerNames.includes("Adyen") && (
            <Grid item onClick={moveToPayment}>
              <Button
                sx={{
                  height: "45px",
                  display: "flex",
                  gap: "5px",
                  border: "1px solid #000",
                }}
              >
                <VisaCardIcon />
                <MasterCardIcon />
                <AmexCardIcon />
              </Button>
            </Grid>
          )}
          {providerNames.includes("TigerTMS Room Charge") && (
            <Grid item onClick={moveToRoomCharges}>
              <Button
                variant="contained"
                className="room-charges-button"
                fullWidth
              >
                Room Charge
              </Button>
            </Grid>
          )}
          {providerNames.includes("Cash") && (
            <Grid item onClick={moveToCashPayment}>
              <Button
                variant="contained"
                className="room-charges-button"
                fullWidth
              >
                Cash
              </Button>
            </Grid>
          )}
          {providerNames.length === 0 && (
            <Grid item xs={8}>
              <Typography variant="h2" align="center">
                Please add a payment method
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>
    </Drawer>
  );
}

PaymentMethodOptions.propTypes = {};

export default PaymentMethodOptions;
