import { createSlice } from "@reduxjs/toolkit";
import { normalize, schema } from "normalizr";
const itemEntity = new schema.Entity("item");

const initialState = {
  isLoading: true,
  error: null,
  data: null,
  orderId: null,
  squareOrderReceipt: null,
  orderPlaced: false,
};

const orderStatus = createSlice({
  name: "orderStatus",
  initialState,
  reducers: {
    reset: (state, { payload }) => {
      return { initialState };
    },
    startLoading: (state) => ({
      ...state,
      isLoading: true,
      error: null,
    }),
    endLoading: (state) => ({ ...state, isLoading: false }),
    updateStatus: (state, { payload }) => {
      const { order = {} } = payload;
      const { orderId, squareOrderReceipt } = order;
      return {
        ...state,
        data: payload,
        orderId: orderId,
        squareOrderReceipt: squareOrderReceipt,
      };
    },
    updateError: (state, { payload }) => ({ ...state, error: payload }),
    updateOrderPlaced: (state, { payload }) => ({
      ...state,
      orderPlaced: payload,
    }),
  },
});

export const actions = orderStatus.actions;

export default orderStatus;
