import {
  TextField as MuiTextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

export const TextField = (props) => <MuiTextField {...props} />;

export const NumberField = (props) => {
  return <MuiTextField {...props} />;
};

export const CheckBoxField = (props) => {
  const label = `${props.label} ${props?.isRequired ? "*" : ""}`;
  return (
    <FormGroup>
      <FormControlLabel control={<Checkbox {...props} />} label={label} />
    </FormGroup>
  );
};
