import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, Typography, Box, Divider } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { getTenant } from "@store/tenant/api";
import { useParams, useHistory, Link } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import { actions as tenantActions } from "@store/tenant/reducer";

const { updateTenantId } = tenantActions;

function OutletList(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { outlets, data } = useSelector((state) => state.tenant);
  const { tenantId } = useParams();

  useEffect(() => {
    dispatch(getTenant({ hierarchyId: tenantId }));
    dispatch(updateTenantId(tenantId));
  }, []);

  if (!outlets.length) {
    return null;
  }

  const getSafeOrderType = (orderTypes) => {
    if (orderTypes && orderTypes.length) {
      return orderTypes[0].orderTypeId;
    }
    return null;
  };

  if (outlets.length === 1) {
    const [outlet] = outlets;
    const { outletId, orderTypes } = outlet;
    history.push(`/${outletId}/${getSafeOrderType(orderTypes)}`);
  }

  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <Typography variant="h2" align="center" sx={{ fontSize: "25px", p: 2 }}>
          {data?.name}
        </Typography>
        <Divider sx={{ borderBottomWidth: 4 }} />
      </Grid>

      {outlets.map((item) => (
        <Grid item xs={12} sx={{ p: 2 }}>
          <Link to={`/${item.outletId}/${getSafeOrderType(item.orderTypes)}`}>
            <Box
              sx={{
                borderRadius: 2,
                mb: "12px",
              }}
            >
              {item.imageUrl ? (
                <div
                  style={{
                    width: "100%",
                    height: 163,
                    backgroundImage: `url(${item.imageUrl})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                    borderRadius: 10,
                  }}
                ></div>
              ) : (
                // <img
                //   src={`${item.imageUrl}`}
                //   alt={item.name}
                //   style={{ height: "auto", width: "100%", borderRadius: 10 }}
                // />
                <Skeleton
                  component="div"
                  variant="rectangular"
                  animation="wave"
                  height={167}
                  width="100%"
                  sx={{
                    borderRadius: 2,
                  }}
                />
              )}
            </Box>
            <Typography
              variant="h2"
              sx={{ color: "#000", textDecoration: "none" }}
            >
              {item.name}
            </Typography>
          </Link>
        </Grid>
      ))}
    </Grid>
  );
}

OutletList.propTypes = {};

export default OutletList;
