import { createSlice, current } from "@reduxjs/toolkit";
import {
  setEmail,
  setTableNumber,
  setName,
  getEmail,
  getName,
  getTableNumber,
} from "@services/LocalStorageService";

import { addItemsHelper } from "./constant";

function checkForAgeRestrictionItem(item) {
  const { attributes = [] } = item;
  const restrict = attributes.filter((item) => {
    if (item.key === "ageRestricted") {
      if (item.value === "1") {
        return true;
      }
    }
    return false;
  });

  return Boolean(restrict.length);
}

const initialState = {
  isLoading: true,
  isItemUpdating: false,
  selectedTipsOption: 0,
  order: null,
  normalizedItems: {},
  items: [],
  selectedItemIds: [],
  ageRestrictedItems: [],
  ageRestriction: false,
  summary: {
    total: 0,
    item: [],
  },
  itemIds: [],
  totalItemsAmount: 0,
  totalOrderAmount: 0,
  tipsAmount: 0,
  orderFor: getTableNumber(),
  error: null,
  comment: "",
  agree: false,
  email: getEmail(),
  name: getName(),
  checkoutFields: {},
};

const order = createSlice({
  name: "order",
  initialState,
  reducers: {
    reset: (state, { payload }) => {
      return {
        ...initialState,
      };
    },
    updateSummary: (state, { payload }) => ({
      ...state,
      summary: { total: payload, items: state.items },
      email: state.email,
    }),
    startLoading: (state) => ({
      ...state,
      isLoading: true,
      error: null,
    }),
    updateItemLoading: (state) => ({
      ...state,
      isItemUpdating: false,
    }),
    endLoading: (state) => ({ ...state, isLoading: false }),
    updateComment: (state, { payload }) => ({ ...state, comment: payload }),
    updateAgree: (state, { payload }) => ({ ...state, agree: payload }),
    updateOrder: (state, { payload }) => ({ ...state, order: payload }),
    deleteItem: (state, { payload }) => {
      console.log({ payload });
      const { items, totalItemsAmount, totalOrderAmount, normalizedItems } =
        state;
      const newItems = [...items];
      const { totalPrice } = payload.item;
      newItems.splice(payload.index, 1);
      const ageRestrictedItems = state.ageRestrictedItems.filter(
        (item) => item !== payload
      );
      const newNormalizedItems = { ...normalizedItems };
      delete newNormalizedItems[payload.item.uniqueId];
      return {
        ...state,
        items: newItems,
        ageRestrictedItems,
        ageRestriction: Boolean(state.ageRestriction.length),
        totalItemsAmount: totalItemsAmount - totalPrice,
        totalOrderAmount: totalOrderAmount - totalPrice,
        isItemUpdating: true,
        selectedItemIds: newItems.map((item) => item.itemId) || [],
        normalizedItems: newNormalizedItems,
      };
    },
    addItem: (state, { payload }) => {
      let { ageRestrictedItems, selectedItemIds } = current(state);
      const newSelectedItemIds = [...selectedItemIds];
      newSelectedItemIds.push(payload?.itemId);

      /**
       * Searching for added item to handle quantity and price addition
       */

      const { items, totalPrice, normalizedItems } = addItemsHelper(
        current(state),
        payload
      );

      const updatedState = {
        ...state,
        items,
        isItemUpdating: true,
        totalItemsAmount: totalPrice,
        totalOrderAmount: totalPrice,
        selectedItemIds: newSelectedItemIds,
        normalizedItems,
      };

      checkForAgeRestrictionItem(payload);
      if (checkForAgeRestrictionItem(payload)) {
        updatedState["ageRestriction"] = true;
        updatedState.ageRestrictedItems = [
          ...ageRestrictedItems,
          payload.itemId,
        ];
      }

      return { ...updatedState };
    },
    emptyCart: (state, { payload }) => {
      return { ...state, items: [] };
    },

    updateTotalOrderAmount: (state, { payload }) => {},
    updateItemQuantity: (state, { payload }) => {
      const { items, totalItemsAmount, totalOrderAmount } = state;
      const { item, quantity, index } = payload;
      let selectedItemPrice = item.price;
      if (item.modifierTotalAmount) {
        selectedItemPrice += item.modifierTotalAmount;
      }
      return {
        ...state,
        isItemUpdating: true,
        items: items.map((item, i) => {
          let price = item.price;
          if (item.modifierTotalAmount) {
            price += item.modifierTotalAmount;
          }

          return index === i
            ? {
                ...item,
                quantity: Number(quantity),
                totalPrice: quantity * price,
              }
            : item;
        }),
        totalItemsAmount:
          totalItemsAmount + (quantity - item.quantity) * selectedItemPrice,
        totalOrderAmount:
          totalOrderAmount + (quantity - item.quantity) * selectedItemPrice,
      };
    },
    updateTipsAmount: (state, { payload }) => ({
      ...state,
      tipsAmount: payload?.tipsAmount,
      selectedTipsOption: payload?.selectedTipsOption,
    }),
    updateOrderFor: (state, { payload }) => {
      setTableNumber(payload);
      return {
        ...state,
        orderFor: payload,
      };
    },
    updateName: (state, { payload }) => {
      setName(payload);
      return {
        ...state,
        name: payload,
      };
    },
    updateEmail: (state, { payload }) => {
      setEmail(payload);
      return {
        ...state,
        email: payload,
      };
    },
    updateCheckoutFields: (state, { payload }) => {
      return {
        ...state,
        checkoutFields: payload,
      };
    },
  },
});

export const actions = order.actions;

export default order;
