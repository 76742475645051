import SessionService from "@services/SessionService";
import { actions as sessionActions } from "@store/session/reducer";

const sessionPayload = {
  platform: "Web",
  platformVersion: "6.0",
  ipAddress: "94.197.109.75",
  appVersion: "1.0.0",
};

const { startLoading, updateSession, updateError, endLoading } = sessionActions;

export const StartSession = () => (dispatch) => {
  dispatch(startLoading());
  return SessionService.createSession(sessionPayload)
    .then(({ session }) => {
      dispatch(updateSession(session));
      const { sessionToken } = session;
      window.localStorage.setItem("sessionToken", sessionToken);
      return session;
    })
    .catch((error) => {
      console.error(error);
      dispatch(updateError(error));
      throw error;
    })
    .finally(() => {
      setTimeout(dispatch(endLoading()), 1000);
    });
};
