import React from "react";
import PropTypes from "prop-types";
import ErrorPageComponent from "@components/error-page";

function ErrorPage(props) {
  return <ErrorPageComponent {...props} />;
}

ErrorPage.propTypes = {};

export default ErrorPage;
