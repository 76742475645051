import React from "react";
import { TextField, NumberField, CheckBoxField } from "./constant";
import { Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const textTypes = ["label"];
const numberTypes = ["number", "decimal"];
const phoneTypes = ["tel"];
const checkBoxTypes = ["checkbox"];
const dateTypes = ["date", "time", "datetime"];

function CustomFields(props) {
  if (textTypes.includes(props.type)) {
    return <Typography variant="h6">{props.value}</Typography>;
  }

  if (numberTypes.includes(props.type)) {
    return <NumberField {...props} />;
  }

  if (phoneTypes.includes(props.type)) {
    return <NumberField {...props} />;
  }

  if (checkBoxTypes.includes(props.type)) {
    return <CheckBoxField {...props} />;
  }

  //   if (dateTypes.includes(props.type)) {
  //     return (
  //       <LocalizationProvider dateAdapter={AdapterDateFns}>
  //         <DatePicker
  //           slotProps={{ textField: { ...props, variant: "standard" } }}
  //         />
  //       </LocalizationProvider>
  //     );
  //   }

  if (dateTypes.includes(props.type)) {
    return <TextField {...props} InputLabelProps={{ shrink: true }} />;
  }

  return <TextField {...props} />;
}

CustomFields.propTypes = {};

export default CustomFields;
