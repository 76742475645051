import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import Error from "./Error";
import { useDispatch, useSelector } from "react-redux";
import { StartSession } from "@root/store/session/api";
import Loading from "@components/loading";
import { useParams } from "react-router-dom";

function RouteWrapper({ layout: Layout, ...props }) {
  const dispatch = useDispatch();
  const { isLoading, error, session } = useSelector((state) => state.session);
  const { outletId, orderType } = useParams();

  useEffect(() => {
    dispatch(StartSession());
  }, []);

  if (isLoading || !session) {
    return <Loading />;
  }

  if (Boolean(error) && outletId && orderType) {
    return "Some error occurred while creating session";
  }

  if (!Layout) {
    return <>{<Route {...props} />}</>;
  }

  return (
    <Layout>
      <Error>
        <Route {...props} />
      </Error>
    </Layout>
  );
}

RouteWrapper.propTypes = {
  layout: PropTypes.func,
};

RouteWrapper.defaultProps = {
  layout: null,
};

export default RouteWrapper;
