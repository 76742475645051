import React, { Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Box, Typography, TextField } from "@mui/material";
import Currency from "@components/currency";
import { actions as OrderActions } from "@store/order/reducer";
import { useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";

const centerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
const boxStyle = {
  border: "1px solid #000",
  borderRadius: "4px",
  height: "28px",
  width: "40px",
  ...centerStyle,
};

function OrderItemOverview({ updateOrder }) {
  const [itemsData, setItemsData] = useState([]);
  const { items, isItemUpdating } = useSelector((state) => state.order);

  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(null);

  useEffect(() => {
    const itemsQuantity = {};
    items.forEach((item, index) => {
      itemsQuantity[index] = item.quantity;
    });
    setQuantity(itemsQuantity);
    setItemsData(items);
  }, [items]);

  const handleCountChange = (itemCount, itemIndex) => {
    const numberRegex = /^\d+$/;
    if (itemCount !== "" && !numberRegex.test(itemCount)) {
      return;
    } else {
      setQuantity({
        ...quantity,
        [itemIndex]: itemCount,
      });
    }
  };

  const handleCountUpdate = (item, itemCount, itemIndex) => {
    const count = Number(itemCount);
    if (count < 1 || count > 99) {
      setQuantity({
        ...quantity,
        [itemIndex]: count < 1 ? 1 : 99,
      });
    }
    dispatch(
      OrderActions.updateItemQuantity({
        item,
        quantity: count < 1 ? 1 : count > 99 ? 99 : count,
        index: itemIndex,
      })
    );
  };

  const removeItem = ({ item, index }) => {
    dispatch(OrderActions.deleteItem({ item, index }));
  };

  console.log({ itemsData });

  return (
    <Grid
      container
      spacing={2}
      position="relative"
      height="100%"
      paddingBottom="10px"
    >
      {isItemUpdating && (
        <Box
          position="absolute"
          width="100%"
          left="8px"
          height="calc(100% + 20px)"
          backgroundColor="rgb(0 0 0 / 40%)"
        >
          <Box
            sx={{
              display: "flex",
              height: "100%",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        </Box>
      )}
      {itemsData.map((item, index) => (
        <Fragment key={JSON.stringify(item)}>
          <Grid item xs={2}>
            <Box sx={centerStyle}>
              <Box sx={boxStyle}>
                <TextField
                  style={{ padding: "0 10px" }}
                  type="text"
                  InputProps={{
                    inputProps: {
                      style: { textAlign: "center" },
                    },
                  }}
                  variant="standard"
                  value={quantity[index]}
                  onChange={(event) =>
                    handleCountChange(event.target.value, index)
                  }
                  onBlur={(event) =>
                    handleCountUpdate(item, event.target.value, index)
                  }
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="subTitle4">{item.name}</Typography>
            <Typography
              variant="subTitle6"
              sx={{ color: "#7C7C7C" }}
              gutterBottom
            >
              {item.comment}
            </Typography>
            {Boolean(item?.selectedModifiers?.length) &&
              item.selectedModifiers.map((modifier) => (
                <Typography variant="subText5">+ {modifier.name}</Typography>
              ))}
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subText5" align="right">
              <Currency value={item.totalPrice} />
            </Typography>
            <Typography
              variant="subText7"
              align="right"
              sx={{ color: "#7C7C7C" }}
              onClick={() => removeItem({ item, index })}
            >
              Remove
            </Typography>
          </Grid>
        </Fragment>
      ))}
    </Grid>
  );
}

OrderItemOverview.propTypes = {};

export default OrderItemOverview;
