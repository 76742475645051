import { createSlice } from "@reduxjs/toolkit";
import { resetAdyen } from "@services/LocalStorageService";

const initialState = {
  isLoading: true,
  adyenConfiguration: {},
  error: null,
  selectedTab: 0,
  selectedItem: null,
};
const payment = createSlice({
  name: "payment",
  initialState,
  reducers: {
    reset: () => {
      resetAdyen();
      return initialState;
    },
    startLoading: (state, { payload }) => ({
      ...state,
      isLoading: true,
      error: null,
    }),
    endLoading: (state, { payload }) => ({ ...state, isLoading: false }),
    updateAdyenConfiguration: (state, { payload }) => ({
      ...state,
      adyenConfiguration: payload,
    }),
    updateError: (state, { payload }) => ({ ...state, error: payload }),
  },
});

export const actions = payment.actions;
export const PaymentActions = actions;

export default payment;
