import React from "react";

import { useSelector } from "react-redux";
import SubCategoryMenu from "@components/sub-category-menu";
import { Box, Typography } from "@mui/material";

function Menu(props) {
  const { isLoading, error } = useSelector((state) => state.menu);
  const stickyHeader = useSelector((state) => state.ui?.stickyHeader);

  if (isLoading) {
    return (
      <Typography align="center" variant="subTitle5">
        Loading
      </Typography>
    );
  }

  if (error) {
    return "Error";
  }

  return (
    <Box padding={"0 16px"}>
      <SubCategoryMenu />
    </Box>
  );
}

Menu.propTypes = {};

export default Menu;
