import OutletService from "@services/OutletService";
import { actions as outletActions } from "@store/outlet/reducer";

const { startLoading, updateOutlet, updateError, endLoading } = outletActions;

export const getOutlet = (payload) => (dispatch) => {
  dispatch(startLoading());
  return OutletService.getOutlet(payload)
    .then((res) => {
      dispatch(updateOutlet(res));
      return res;
    })
    .catch((error) => {
      console.error(error);
      dispatch(updateError(error));
      throw error;
    })
    .finally(() => {
      dispatch(endLoading());
    });
};
