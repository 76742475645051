import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import Currency from "@components/currency";
import FormHelperText from "@mui/material/FormHelperText";
import { Box, Divider } from "@mui/material";

function RadioModifier({
  modifier,
  modifiedLabel,
  onChange,
  value,
  errors,
  attributes,
}) {
  const radioValue = value[modifier.itemId];
  const itemClicked =
    ({ name, value }) =>
    () => {
      if (radioValue === String(value) && !modifier?.isMandatory) {
        return onChange({ target: { name, value: null } });
      }

      onChange({ target: { name, value: String(value) } });
    };

  return (
    <Grid item xs={12}>
      <FormControl
        error={Boolean(errors[modifier.itemId])}
        sx={{ width: "100%" }}
      >
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name={`${modifier.itemId}`}
          value={radioValue}
        >
          <Grid container>
            {modifier.items.map((item, index) => (
              <>
                <Grid
                  xs={12}
                  item
                  container
                  sx={{
                    padding: "16px 0 0 0",
                    borderBottom:
                      modifier.items.length - 1 === index
                        ? ""
                        : "1px solid #e0e0e0",
                    paddingBottom:
                      modifier.items.length - 1 === index ? 0 : "16px",
                  }}
                  onClick={itemClicked({
                    name: `${modifier.itemId}`,
                    value: item.itemId,
                  })}
                >
                  <Grid item xs={10}>
                    <Typography variant="h5">{item.name}</Typography>
                    {item.price !== 0 && (
                      <Typography variant="subTitle5">
                        {item.price < 0 ? "-" : "+"}{" "}
                        <Currency value={Math.abs(item.price)} />
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    {!attributes.isDigitalMenu && (
                      <FormControlLabel
                        value={item.itemId}
                        control={
                          <Radio
                            checked={String(item.itemId) === String(radioValue)}
                          />
                        }
                        sx={{
                          width: "20px",
                          height: "20px",
                          margin: 0,
                          "& .MuiRadio-root": {
                            padding: 0,
                          },
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
                <Divider />
              </>
            ))}
          </Grid>
        </RadioGroup>
        {errors[modifier.itemId] && (
          <FormHelperText>{errors[modifier.itemId]}</FormHelperText>
        )}
      </FormControl>
    </Grid>
  );
}

RadioModifier.propTypes = {};

export default RadioModifier;
