import APIService from "./APIService";

export default class OutletService extends APIService {
  static getOutlet(params) {
    const queryParameter = this.buildQuery(params);
    return this.get(`/outlet${queryParameter}`)
      .then(this.getResponseData)
      .catch(this.getResponseError);
  }
  static getOutlets(params) {
    const queryParameter = this.buildQuery(params);
    return this.get(`/outlet${queryParameter}`)
      .then(this.getResponseData)
      .catch(this.getResponseError);
  }
}
