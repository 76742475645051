import CheckoutService from "@services/CheckoutService";
import { actions as orderStatusAction } from "@store/order-status/reducer";
import { action as UIActions } from "@store/ui/reducer";
import { errorCode } from "./constant";

const {
  startLoading,
  updateStatus,
  updateError,
  endLoading,
  updateOrderPlaced,
} = orderStatusAction;

export const orderStatus = (payload) => (dispatch) => {
  dispatch(startLoading());
  return CheckoutService.setOrderStatus(payload)
    .then((res) => {
      dispatch(updateStatus(res));
      const { order } = res;
      if (order.statusName === "Failed to send") {
        throw errorCode.UNABLE_TO_COMMUNICATE_TO_POS;
      }
      dispatch(updateOrderPlaced(true));
      return res;
    })
    .catch((error) => {
      console.error(error);
      dispatch(updateError(error));
      throw error;
    })
    .finally(() => {
      dispatch(endLoading());
      dispatch(UIActions.endMenuPageLoading());
    });
};
