import APIService from "./APIService";

export default class TenantService extends APIService {
  static getTenant(params) {
    const queryParameter = this.buildQuery(params);
    return this.get(`/outlet${queryParameter}`)
      .then(this.getResponseData)
      .catch(this.getResponseError);
  }
}
