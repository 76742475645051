import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { getAdyenPaymentSession } from "@store/payment/api";
import { useDispatch, useSelector } from "react-redux";
import Loading from "@components/loading";
import AdyenElements from "./AdyenElements";

function Adyen(props) {
  const dispatch = useDispatch();
  const { totalPayment, startOrder } = props;
  const { isLoading, adyenConfiguration = {} } = useSelector(
    (state) => state.payment
  );
  const outlet = useSelector((state) => state.outlet);
  // End  Hooks initialization

  const { outletId } = outlet;

  // Adyen payment initialization
  const init = () => {
    console.log("Getting config for => ", totalPayment);
    startOrder().then(({ order }) => {
      const { orderId } = order;
      dispatch(
        getAdyenPaymentSession({
          outletId: outletId,
          value: totalPayment,
          returnUrl: window.location.href,
          orderId,
        })
      );
    });
  };

  useEffect(() => init(), []);
  // End Adyen payment

  if (isLoading) {
    return <Loading />;
  }
  if (!adyenConfiguration?.id) {
    return <Loading />;
  }
  console.log("Adyen loading for total amount => ", totalPayment);
  return <AdyenElements {...props} adyenConfiguration={adyenConfiguration} />;
}

Adyen.propTypes = {};

export default Adyen;
