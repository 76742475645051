import Adyen from "@containers/adyen";

const EMAIL = "email";
const OUTLET_ID = "outletId";
const TABLE_NUMBER = "tableNumber";
const NAME = "name";
const ADYEN = " adyen-checkout__session";

export const defaultTableNumber = {
  type: "",
  value: "",
  identifier: null,
};

const convertJSON = (val) => {
  try {
    return JSON.parse(val);
  } catch (e) {
    return null;
  }
};

export const setEmail = (email) => localStorage.setItem(EMAIL, email);
export const setOutletId = (outletId) =>
  localStorage.setItem(OUTLET_ID, outletId);
export const setTableNumber = (tableNumber) =>
  localStorage.setItem(TABLE_NUMBER, JSON.stringify(tableNumber));

export const setName = (name) => localStorage.setItem(NAME, name);

export const getName = () => localStorage.getItem(NAME) || "";
export const getOutletId = () => {
  const outletId = localStorage.getItem(OUTLET_ID);
  if (outletId === "undefined") {
    return undefined;
  }
  return outletId;
};
export const getTableNumber = () =>
  convertJSON(localStorage.getItem(TABLE_NUMBER)) || defaultTableNumber;
export const getEmail = () => localStorage.getItem(EMAIL) || "";

export const resetAdyen = () => {
  localStorage.removeItem(ADYEN);
};

export const getCachedValues = (name) => {
  return parseJson(localStorage.getItem(name)) || "";
};

const parseJson = (param) => {
  try {
    return JSON.parse(param);
  } catch (e) {
    return param;
  }
};
