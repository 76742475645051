import { format } from "mathjs";

export const formatToCurrency = (number) => {
  if (!isNaN(parseFloat(number).toFixed(2))) {
    // const rounded = Math.floor(number * 100) / 100;
    // Fix because sometime it returns a string in double quotes
    return String(
      format(number, { notation: "fixed", precision: 2 })
    ).replaceAll('"', " ");
  }
  return 0;
};
