import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { action as UIActions } from "@store/ui/reducer";
import "./style.css";

const position = {
  vertical: "top",
  horizontal: "center",
};
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SimpleSnackbar({ text, severity }) {
  const dispatch = useDispatch();
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(UIActions.setSnackBarMessage(""));
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className="snack-bar">
      <Snackbar
        anchorOrigin={position}
        autoHideDuration={6000}
        onClose={handleClose}
        action={action}
        open
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          sx={{ width: "100%" }}
          icon={false}
        >
          {text}
        </Alert>
      </Snackbar>
    </div>
  );
}

//#227d3abd
// 49px
