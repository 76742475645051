import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid, IconButton, InputAdornment } from "@mui/material";
import Icon from "@components/icons";
import FilterAllergen from "./FilterAllergen";
import { TextField } from "./constants";
import { useFormik } from "formik";
import { filterMenu } from "@root/store/menu/api";
import { useDispatch, useSelector } from "react-redux";

function MenuToolBar() {
  const [selectedFilter, selectFilter] = useState(null);
  const { attributes } = useSelector((state) => state.outlet);
  const { selectedFilters, filters } = useSelector((state) => state.menu);
  const filterOptions = attributes?.allergenFilters;
  const dispatch = useDispatch();
  const handleFilter = () => {};

  const onSubmit = (values) => {
    dispatch(
      filterMenu({
        allergens: filterOptions.filter((item) =>
          values?.filterAllergen.includes(item.key)
        ),
      })
    );
  };

  console.log({ filters });
  const form = useFormik({
    initialValues: {
      filterAllergen: selectedFilters || [],
      searchString: filters?.searchString,
    },
    onSubmit: onSubmit,
  });

  const { handleChange: onChange, handleSubmit, values } = form;

  const handleChange = (e) => {
    onChange(e);
  };

  const handleSearch = (e) => {
    const {
      target: { value },
    } = e;
    onChange(e);
    dispatch(filterMenu({ searchString: value }));
  };

  console.log({ values });

  return (
    <>
      <Grid container columnSpacing={2} padding={2}>
        {filterOptions.length > 0 && (
          <Grid item>
            <IconButton onClick={() => selectFilter("filterAllergen")}>
              <Icon variant="filter" viewBox="0 0 30 24" />
            </IconButton>
          </Grid>
        )}
        <Grid item flexGrow={1}>
          <TextField
            placeholder="Search"
            size="small"
            name="searchString"
            onChange={(e) => handleSearch(e)}
            value={values?.searchString}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon variant="search" />
                </InputAdornment>
              ),
            }}
            fullWidth
          />
        </Grid>
      </Grid>
      {selectedFilter === "filterAllergen" && (
        <FilterAllergen
          {...{
            handleChange,
            handleSubmit,
            values,
            selectFilter,
            handleFilter,
            filterOptions,
          }}
        />
      )}
    </>
  );
}

MenuToolBar.propTypes = {};

export default MenuToolBar;
