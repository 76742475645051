/* eslint-disable no-unused-vars */
import React from "react";
import { Router, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Box, Container } from "@mui/material";
import { useSelector } from "react-redux";
import Routes from "./route";
import Snackbar from "@components/snackbar";
import { useDispatch } from "react-redux";

import logo from "./logo.svg";
import "./App.css";

export const history = createBrowserHistory();

function App() {
  const dispatch = useDispatch();
  const { snackBarInfo } = useSelector((state) => state.ui);
  const { message, severity } = snackBarInfo;

  return (
    <React.Fragment>
      <Box className="portrait" sx={{ bgcolor: "#f2f2f2" }}>
        <Router history={history}>
          <Route path="/" component={Routes} />
        </Router>
      </Box>
      {Boolean(message) && <Snackbar text={message} severity={severity} />}
    </React.Fragment>
  );
}

export default App;
