import {
  Button as MuiButton,
  TextField as MuiTextField,
  styled,
} from "@mui/material";

export const filterOptions = [
  {
    icon: "GF",
    label: "Gluten Free",
    key: "glutenFree",
  },
  {
    icon: "LF",
    label: "Lactose Free",
    key: "lactoseFree",
  },
  {
    icon: "SF",
    label: "Shellfish Free",
    key: "shellfishFree",
  },
  {
    icon: "V",
    label: "Vegetarian",
    key: "vegetarian",
  },
  {
    icon: "VG",
    label: "Vegan",
    key: "vegan",
  },
];

export const Button = styled(MuiButton)({
  backgroundColor: "",
  borderRadius: "42px",
  backgroundColor: "#fff ",
  color: "#000",
  textTransform: "capitalize",
});

export const TextField = styled(MuiTextField)({
  backgroundColor: "",
  borderRadius: "42px",
  backgroundColor: "#fff ",
  color: "#000",
  textTransform: "capitalize",
  "& fieldset": {
    display: "none",
  },
});
