import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import Currency from "@components/currency";
import Allergens from "@components/allergens";
import DynamicResizeHandler from "./DynamicResizeHandler";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import { formatToCurrency } from "@root/util/currency";

function ItemAction({ selectCount, attributes, onClick, ...props }) {
  if (attributes?.outOfStock) {
    return (
      <Box
        position="absolute"
        sx={{
          backgroundColor: "#FFE2E9",
          borderRadius: "12px",
          height: "32px",
          bottom: 0,
          right: 0,
          color: "#FF3C6A",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "14px",
          fontWeight: 500,
          padding: "1px 12px",
        }}
      >
        Out of stock
      </Box>
    );
  }
  if (props.isDigitalMenu) {
    return null;
  }
  return (
    <Box
      position="absolute"
      onClick={onClick}
      sx={{
        backgroundColor: "#000",
        borderRadius: "12px",
        height: "32px",
        width: "32px",
        bottom: 0,
        right: 0,
        color: "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {selectCount.length > 0 ? selectCount.length : <AddIcon />}
    </Box>
  );
}

function ItemContainer({
  item,
  selectCount,
  isDigitalMenu,
  handleItemSelect,
  onClick,
}) {
  const { currencySymbol } = useSelector((state) => state.outlet);
  return (
    <Box
      sx={{
        width: "100%",
        paddingRight: item.imageUrl ? "118px" : "0",
        position: "relative",
        height: item.imageUrl ? "104px" : "fit-content",
        paddingTop: "16px",
        paddingBottom: "16px",
      }}
    >
      <Box
        height="100%"
        sx={{
          display: "flex",
          gap: "4px",
          flexDirection: "column",
          // justifyContent:
          //   !item.description && item.imageUrl ? "space-between" : "unset",
          justifyContent: "space-between",
        }}
        onClick={onClick}
      >
        <Typography variant="h4">{item.name}</Typography>
        {item.description && (
          <DynamicResizeHandler maxHeight={58}>
            <Typography
              component="p"
              variant="subText5"
              sx={{
                color: "#7C7C7C",
                minHeight:
                  Boolean(item?.price) &&
                  item?.transformedAttributes?.allergens?.length > 0
                    ? "58px"
                    : "fit-content",
                overflow: "hidden",
              }}
              dangerouslySetInnerHTML={{
                __html: item?.description,
              }}
            />
          </DynamicResizeHandler>
        )}
        <Box
          display="flex"
          gap="5px"
          width="95%"
          alignItems="center"
          id={`item-footer-${item.itemId}`}
        >
          {item.price > 0 && (
            <Typography
              variant="subTitle4"
              component="span"
              id={`item-price-${item.itemId}`}
              sx={{ flexShrink: 0 }}
            >
              <Currency value={item.price} />
            </Typography>
          )}
          {item?.transformedAttributes?.allergens && (
            <Allergens
              attributes={item?.transformedAttributes}
              maxWidth={200}
              itemId={item.itemId}
              name={item.name}
              price={`${currencySymbol} ${formatToCurrency(item.price)}`}
            />
          )}
        </Box>
      </Box>
      <Box
        sx={{
          position: "absolute",
          right: "0px",
          top: "0",
          height: "calc(100% - 24px)",
          padding: "12px 0",
        }}
      >
        <Box
          position="relative"
          sx={{
            height: "100%",
            maxWidth: "112px",
          }}
        >
          {" "}
          <ItemAction
            selectCount={selectCount}
            attributes={item.transformedAttributes}
            isDigitalMenu={isDigitalMenu}
            onClick={() => {
              handleItemSelect(item);
            }}
          />
          {item.imageUrl && (
            <Box
              sx={{
                width: "112px",
                height: "100%",
                backgroundImage: `url('${item.imageUrl}')`,
                backgroundSize: "cover" /* <------ */,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                borderRadius: "12px",
              }}
              onClick={onClick}
            ></Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

ItemContainer.propTypes = {};

export default ItemContainer;
