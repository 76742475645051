import React, { useEffect, useState } from "react";
import { Grid, Box } from "@mui/material";
import OrderItemOverview from "@components/order-item-overview";
import AmountOverview from "@components/amount-overview";

import PaymentMethodOptions from "@components/payment-method-options";

import ItemDetailDialog from "@components/item-detail-dialog";

import IconButton from "@mui/material/IconButton";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";

import Tips from "@components/tips";

import { useDispatch, useSelector } from "react-redux";
import { getOrderReview } from "@store/checkout/api";
import { actions as CheckoutActions } from "@store/checkout/reducer";
import { ViewOrderForm } from "@components";

import { useHistory, useParams, generatePath } from "react-router-dom";
import "./styles.css";

function ViewOrder(props) {
  const [agreeError, setAgreeError] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoading, error } = useSelector((state) => state.checkout);
  const { orderTypes } = useSelector((state) => state.checkout);
  const { showTableAndRoomSelector } = useSelector(
    (state) => state.viewOrderPage
  );
  const { detail, attributes, removeSurcharges } = useSelector(
    (state) => state.outlet
  );
  const {
    items = {},
    totalItemsAmount,
    tipsAmount,
  } = useSelector((state) => state.order);
  const { agree, orderFor } = useSelector((state) => state.order);
  const { currencyCode, countryCode } = detail;
  const { orderType, outletId, locationId } = useParams();

  const itemSelectedForOrder = Object.values(items);

  const payload = {
    checkoutOptions: {
      outletId,
    },
    paymentMethod: {
      channel: "Web",
      countryCode,
      currency: currencyCode,
      outletId,
      value: 334,
    },
    orderTotals: {
      outletId,
      orderTypeId: parseInt(orderType),
      subTotal: totalItemsAmount,
      items,
    },
  };

  if (removeSurcharges) {
    payload.orderTotals["removeSurcharge"] = true;
  }

  const placeOrder = () => {
    return dispatch(getOrderReview(payload));
  };

  useEffect(() => {
    if (itemSelectedForOrder.length) {
      console.log("Total refreshed");
      placeOrder();
    }
  }, [items, tipsAmount, removeSurcharges]);

  const goToMenu = () => {
    const pathName = generatePath("/:outletId/:orderType/:locationId?", {
      locationId,
      orderType,
      outletId,
    });

    history.push(pathName);
  };

  if (error && !agreeError) {
    setAgreeError(`Something went wrong. Please try again later `);
  }

  if (!itemSelectedForOrder.length) {
    goToMenu();
  }

  return (
    <>
      <Box p={3} pb="120px">
        <Grid container rowSpacing={4}>
          <Grid item xs={12}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={goToMenu}
              aria-label="close"
            >
              <KeyboardBackspaceRoundedIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <OrderItemOverview updateOrder={placeOrder} />
          </Grid>

          {!attributes.hideTipping && (
            <Grid item xs={12}>
              <Tips />
            </Grid>
          )}

          <Grid item xs={12}>
            <AmountOverview />
          </Grid>
          <Grid item xs={12}>
            <ViewOrderForm isLoading={isLoading} error={error} />
          </Grid>

          <PaymentMethodOptions />
          <ItemDetailDialog variant="edit" />
        </Grid>
      </Box>
    </>
  );
}

ViewOrder.propTypes = {};

export default ViewOrder;
