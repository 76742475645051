import styled from "@emotion/styled";
import React from "react";
import { memo } from "react";

function ImageContainer({
  height,
  imageUrl,
  altText,
  fit,
  position,
  opacity,
  width,
  borderRadius,
}) {
  const Image = styled.img`
    width: ${width};
    height: ${height};
    object-fit: ${fit};
    margin-top: ${position};
    border-radius: ${borderRadius};
  `;
  const Overlay = styled.div`
    position: absolute;
    top: 0;
    background: rgb(124 124 124 / 53%);
    width: 100%;
    height: ${height}vh;
    opacity: ${opacity}%;
  `;
  return (
    <>
      <Image src={imageUrl} alt={altText} fit="cover" width="102px" />
      {opacity && <Overlay></Overlay>}
    </>
  );
}

ImageContainer.defaultProps = {
  width: "100%",
  borderRadius: "12px",
};

export default memo(ImageContainer);
