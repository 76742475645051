import order from "@root/store/order-summary/reducer";

export const checkValidOrderType = (history, orderTypeId) => (apiResponse) => {
  const { orderTypes } = apiResponse;
  const orderTypeIds = orderTypes.map((orderType) =>
    String(orderType.orderTypeId)
  );
  if (!orderTypeIds.includes(orderTypeId)) {
    history.push("/not-found");
  }
};

export const observerConfig = {
  threshold: 1,
  rootMargin: "500px",
};
