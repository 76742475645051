import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { reset } from "@store/resetAPI";
import { Redirect, useParams } from "react-router-dom";

function CacheControl(props) {
  const [savedState, setState] = useState({});
  const state = useSelector((state) => state);
  const { outletId, orderType } = useParams();

  const { orderTotals = {} } = savedState?.checkout || {};
  const { orderPlaced } = savedState?.orerStatus || {};
  const totalAmount = orderTotals?.totalPrice;
  const dispatch = useDispatch();

  useEffect(() => {
    setState(JSON.parse(JSON.stringify(state)));
    window.localStorage.removeItem("persist:root");
    window.localStorage.removeItem("adyen-checkout__session");
    window.sessionStorage.clear();
    dispatch(reset());
  }, []);

  if (Object.keys(savedState).length) {
    const invalidRequest = orderPlaced || totalAmount < 0;
    if (invalidRequest) {
      return <Redirect to={`/${outletId}/${orderType}`} />;
    }
  }

  if (!Object.keys(savedState).length) {
    return null;
  }

  return React.cloneElement(props.children, {
    state: savedState,
  });
}

CacheControl.propTypes = {};

export default CacheControl;
