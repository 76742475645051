import React from "react";
import PropTypes from "prop-types";
import { Grid, Box } from "@mui/material";
import Payment from "@containers/payment";
import ReviewOrder from "@components/review-order";
import IconButton from "@mui/material/IconButton";
import CashPaymentContainer from "@containers/cash-payment";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";

import { useHistory } from "react-router-dom";

import "./styles.css";

function CashPayment(props) {
  const history = useHistory();

  const goToMenu = () => history.goBack();

  return (
    <>
      <Box p={3} pb="120px">
        <Grid container rowSpacing={4}>
          <Grid item xs={12}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={goToMenu}
              aria-label="close"
            >
              <KeyboardBackspaceRoundedIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <ReviewOrder />
          </Grid>
          <Grid item xs={12}>
            <Payment>
              <CashPaymentContainer goToMenu={goToMenu} />
            </Payment>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

CashPayment.propTypes = {};

export default CashPayment;
