import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  version: "1.0.0",
  isMenuPageLoading: true,
  snackBarInfo: {
    message: "",
    severity: "warning",
  },
  stickyHeader: false,
};
const ui = createSlice({
  name: "ui",
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    startMenuPageLoading: (state, { payload }) => ({
      ...state,
      isMenuPageLoading: true,
    }),
    endMenuPageLoading: (state, { payload }) => ({
      ...state,
      isMenuPageLoading: false,
    }),
    setSnackBarMessage: (state, { payload }) => ({
      ...state,
      snackBarInfo: payload,
    }),
    setSnackBarError: (state, { payload }) => ({
      ...state,
      snackBarInfo: {
        severity: "error",
        message: payload,
      },
    }),

    toggleStickyHeader: (state, { payload }) => ({
      ...state,
      stickyHeader: payload,
    }),

    setSnackBarSuccess: (state, { payload }) => ({
      ...state,
      snackBarInfo: {
        severity: "success",
        message: payload,
      },
    }),
  },
});

export const action = ui.actions;
export const UiAction = action;

export default ui;
