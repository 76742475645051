import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@mui/material/styles";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { Provider } from "react-redux";

import { store } from "./store";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import theme from "./theme";
import ErrorBoundary from "./route/Error";

const persistor = persistStore(store);
// This is to handle inter reducer state sharing
window.store = store;
// Global error handling
window.onerror = function (message, source, lineno, colno, error) {
  console.error(
    "Unhandled error:",
    message,
    "at",
    source,
    "line",
    lineno,
    "column",
    colno,
    "error object:",
    error
  );
};

window.addEventListener("unhandledrejection", function (event) {
  console.error("Unhandled rejection:", event.reason);
});

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </ErrorBoundary>,

  document.getElementById("app")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
