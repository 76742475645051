import ErrorPage from "@pages/error-page";
import React from "react";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.log("Catched error");
    console.log(error);
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    //   logErrorToMyService(error, errorInfo);
    console.log(error, errorInfo);

    console.log("Error Catched");
  }
  handleActionClick = () => {
    localStorage.clear();
    window.location.reload();
  };

  render() {
    console.log("Error Catched");
    if (this.state.hasError) {
      window.localStorage.clear();
      // You can render any custom fallback UI
      return (
        <ErrorPage
          message="Looks like something went wrong"
          actionButtonLabel="Retry"
          onClickActionButton={this.handleActionClick}
        />
      );
    }

    return this.props.children;
  }
}
