import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Drawer as MuiDrawer,
  Typography,
  Box,
  Divider,
  InputAdornment,
  Button,
  styled,
} from "@mui/material";
import FloatingButton from "@components/floating-button";
import Icon from "@components/icons";
import FilterAllergenForm from "@components/filter-allergen-form";
import { useFormik } from "formik";
import { TextField } from "@components/menu-tool-bar/constants";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { useDispatch, useSelector } from "react-redux";
import { filterMenu } from "@root/store/menu/api";

const Drawer = styled(MuiDrawer)({
  ".MuiDrawer-paper": {
    position: "absolute", //imp //drawer width
    maxWidth: "548px",
    margin: "auto",
  },
});

function MenuFilterDrawer({ onClose, title, enabledFeature, ...props }) {
  const [expandedAllergen, setExpandedAllergen] = useState(false);

  const dispatch = useDispatch();

  const { attributes } = useSelector((state) => state.outlet);
  const filterOptions = attributes?.allergenFilters;
  const { selectedFilters, filters } = useSelector((state) => state.menu);

  const onSubmit = (values) => {
    const allergens = filterOptions.filter((item) =>
      values?.filterAllergen.includes(item.key)
    );
    dispatch(
      filterMenu({
        allergens,
        searchString: values.searchString,
      })
    );
    onClose(null);
  };

  const getInitValues = () => {
    console.log({ searchString: filters?.searchString });
    return {
      filterAllergen: selectedFilters,
      searchString: filters?.searchString,
    };
  };

  const form = useFormik({
    initialValues: getInitValues(),
    onSubmit: onSubmit,
  });

  const { handleChange, handleSubmit, values } = form;

  const handleToggleAllergen = () => {
    setExpandedAllergen((state) => !state);
  };

  const handleSelectCategory = (id) => {
    props.handleSelectCategory({}, id);
    onClose();
  };

  const canShowSubmitButton =
    values.filterAllergen.length > 0 || values.searchString.length > 0;

  return (
    <Drawer
      anchor="bottom"
      open
      onClose={() => onClose(null)}
      PaperProps={{
        sx: {
          borderRadius: "24px 24px 0 0",
          padding: "0 16px 16px 16px",
        },
      }}
    >
      <Box
        position="relative"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        paddingTop="70px"
      >
        <Box
          sx={{
            borderRadius: "24px 24px 0 0",
            height: "432px",
            overflow: "scroll",
            backgroundColor: "#fff",
          }}
        >
          <Box
            sx={{
              height: "62px",
              position: "absolute",
              top: "0",
              width: "100%",
              paddingTop: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h2">{title}</Typography>
              <Box
                sx={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "40px",
                  bgcolor: "#E0E0E0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => onClose(null)}
              >
                <Icon
                  variant="cancel"
                  viewBox="0 0 12 12"
                  style={{ fontSize: "12px" }}
                />
              </Box>
            </Box>
            <Divider sx={{ width: "100%", mt: 0.5 }} />
          </Box>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                size="small"
                placeholder="search"
                name="searchString"
                onChange={handleChange}
                sx={{ backgroundColor: "#f2f2f2" }}
                value={values?.searchString}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon variant="search" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {enabledFeature.includes("filterAllergen") && (
              <Grid item xs={12}>
                <Box>
                  {filterOptions.length > 0 && (
                    <Button fullWidth onClick={handleToggleAllergen}>
                      Allergens
                      {expandedAllergen ? (
                        <ExpandMoreRoundedIcon />
                      ) : (
                        <ExpandLessRoundedIcon />
                      )}
                    </Button>
                  )}
                  {expandedAllergen && (
                    <Box>
                      <Divider />
                      <Typography variant="h3" sx={{ padding: "12px 0" }}>
                        Filter by allergen
                      </Typography>
                      <Divider />
                      <FilterAllergenForm
                        handleChange={handleChange}
                        filterOptions={filterOptions}
                        values={values}
                      />
                    </Box>
                  )}

                  <Box sx={{ marginTop: "20px", pt: 1 }}>
                    <FloatingButton onClick={handleSubmit} position="relative">
                      Apply filters
                    </FloatingButton>
                  </Box>
                </Box>
              </Grid>
            )}
            <Grid item xs={12}>
              <Divider />
              <Typography variant="h3" sx={{ padding: "12px 0" }}>
                Menu
              </Typography>
              <Divider />
              {props.menu.categories.reduce((acc, parentCategory) => {
                const items = parentCategory.categories.map((category) => (
                  <Typography
                    variant="h3"
                    align="center"
                    sx={{ mb: 1.5, mt: 1.5 }}
                    onClick={() => handleSelectCategory(category.categoryId)}
                  >
                    {category.name}
                  </Typography>
                ));
                return [...acc, ...items];
              }, [])}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Drawer>
  );
}

MenuFilterDrawer.propTypes = {};

MenuFilterDrawer.defaultProps = {
  enabledFeature: ["filterAllergen"],
};

export default MenuFilterDrawer;
