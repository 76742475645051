const validItemFilter = (item) => {
  if (!item.visible) {
    return false;
  }
  return item;
};

export const modifiersTransformer = (modifierGroups) => {
  const newModifierGroups = [];

  const filteredVisibleModifierGroups = modifierGroups.filter(
    (modifierGroup) => modifierGroup.visible
  );

  filteredVisibleModifierGroups.forEach((modifierGroup) => {
    const { items: modifiers } = modifierGroup;
    const newModifiers = [];

    modifiers.forEach((modifier) => {
      if (validItemFilter(modifier)) newModifiers.push(modifier);
    });

    if (newModifiers.length) {
      newModifierGroups.push({
        ...modifierGroup,
        items: newModifiers,
      });
    }
  });
  return newModifierGroups;
};
