import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, Box, Divider } from "@mui/material";
import ConfirmationOverview from "@components/confirmation-overview";
import EmailReceipt from "@components/email-receipt";
import ConfirmedOrderSummary from "@components/confirmed-order-summary";
import "./styles.css";

function OrderConfirmation({ state }) {
  const { order } = state;
  const { email } = order;

  return (
    <Box pb="120px">
      <Grid container rowSpacing={4}>
        <Grid item xs={12} sx={{ p: 3 }}>
          <ConfirmationOverview {...state} />
        </Grid>
        <Grid item xs={12} sx={{ pt: 0 }}>
          <Divider sx={{ width: "100%" }} />
        </Grid>
        {!Boolean(email) && (
          <Grid item xs={12} sx={{ p: 3 }}>
            <EmailReceipt {...state} />
          </Grid>
        )}
        <Grid item xs={12} sx={{ p: 3 }}>
          <ConfirmedOrderSummary {...state} />
        </Grid>
      </Grid>
    </Box>
  );
}

OrderConfirmation.propTypes = {};

export default OrderConfirmation;
