import React from "react";
import { useSelector } from "react-redux";
import { formatToCurrency } from "@root/util/currency";

function Currency({ value, symbol }) {
  const { currencySymbol } = useSelector((state) => state.outlet);
  return (
    <>
      {currencySymbol}
      {symbol}
      {formatToCurrency(value)}
    </>
  );
}

Currency.propTypes = {};

export default Currency;
