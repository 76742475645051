export const getSelectedModifiers = (selectedModifier = [], modifiers) => {
  const mod = {};

  const multiSelectMod = modifiers
    .filter((item) => item.allowMultiSelect)
    .map((item) => item.itemId);

  for (const modifier of selectedModifier) {
    if (multiSelectMod.includes(modifier.parentItemId)) {
      const selectedItems = mod[modifier.parentItemId] || [];
      mod[modifier.parentItemId] = [...selectedItems, String(modifier.itemId)];
    } else {
      mod[modifier.parentItemId] = modifier.itemId;
    }
  }

  return mod;
};
