import React from "react";
import PropTypes from "prop-types";
import { Button as MuiButton, Box, Typography, Grid } from "@mui/material";

function FloatingButton({
  onClick,
  message,
  error,
  children,
  buttonProps,
  position,
}) {
  return (
    <Box
      position={position}
      bottom="18px"
      width="100%"
      left="0px"
      onClick={onClick}
    >
      <Box position="relative" display="flex" justifyContent="center">
        <Grid container>
          <Grid item xs={12}>
            {message && (
              <Typography
                variant="h3"
                sx={{ marginBottom: "10px" }}
                align="center"
              >
                {message}
              </Typography>
            )}
            {error && (
              <Typography
                variant="h3"
                sx={{ marginBottom: "10px", color: "red" }}
                align="center"
              >
                {error}
              </Typography>
            )}
          </Grid>

          <Grid container item xs={12} justifyContent="center">
            <MuiButton
              variant="contained"
              {...buttonProps}
              fullWidth
              sx={{
                borderRadius: "100px",
                padding: "16px",
                fontWeight: 600,
                fontSize: 16,
                textTransform: "unset",
                width: "calc(100% - 32px)",
              }}
            >
              {children}
            </MuiButton>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

FloatingButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
  ]).isRequired,
  message: PropTypes.string,
  buttonProps: PropTypes.object,
};
FloatingButton.defaultProp = {
  onClick: () => {},
  message: null,
  buttonProps: {},
  position: "fixed",
};

export default FloatingButton;
