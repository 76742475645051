import { configureStore } from "@reduxjs/toolkit";
import { routerMiddleware, routerReducer } from "react-router-redux";
import { combineReducers } from "redux";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import { history } from "../App";

import ui from "./ui/reducer";
import outlet from "./outlet/reducer";
import session from "./session/reducer";
import menu from "./menu/reducer";
import order from "./order/reducer";
import checkout from "./checkout/reducer";
import payment from "./payment/reducer";
import tenant from "./tenant/reducer";
import orderStatus from "./order-status/reducer";
import viewOrderPage from "./pages/viewOrderPage";

const reducers = combineReducers({
  ui: ui.reducer,
  outlet: outlet.reducer,
  routing: routerReducer,
  menu: menu.reducer,
  session: session.reducer,
  order: order.reducer,
  checkout: checkout.reducer,
  payment: payment.reducer,
  tenant: tenant.reducer,
  orderStatus: orderStatus.reducer,
  viewOrderPage: viewOrderPage.reducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(routerMiddleware(history));
  },
});
