import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Drawer,
  Grid,
  Button,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import { useSelector } from "react-redux";
import ScheduleIcon from "@mui/icons-material/Schedule";
import "./style.css";

function OutletUnavailableDialog({ onClose, onRetry }) {
  const [hideDialog, toggleDialogBox] = useState(false);
  const { isAvailable, unavailableMessage, availableTime } = useSelector(
    (state) => state.outlet
  );

  if (!Boolean(unavailableMessage) || hideDialog) {
    return null;
  }

  const parseTime = (dateTime) => {
    // 9/2/2022 3:00:00 PM
    try {
      const [a, time, zone] = String(dateTime).split(" ");
      if (!time) {
        return null;
      }

      const [first, second] = String(time).split(":");

      return `${first}:${second} ${zone}`;
    } catch (e) {
      return null;
    }
  };

  return (
    <Drawer anchor="bottom" open onClose={onClose} className="">
      <Box p={5}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          columnSpacing={2}
          rowSpacing={3}
        >
          <Grid item xs={12}>
            <Typography
              variant="h3"
              align="center"
              pb={2}
              fontWeight={500}
              gutterBottom
            >
              {unavailableMessage}
            </Typography>
            <Typography
              variant="h3"
              align="center"
              color="#7C7C7C"
              fontWeight={500}
            >
              <Box display="flex" justifyContent="center" alignItems="center">
                <Box
                  component="span"
                  color="#000"
                  alignItems="center"
                  display="flex"
                  marginRight={1}
                >
                  <ScheduleIcon />
                </Box>
                Ordering available from {parseTime(availableTime)}
              </Box>
            </Typography>
          </Grid>

          {isAvailable && (
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  onClick={() => toggleDialogBox(true)}
                  sx={{
                    textTransform: "capitalize",
                    borderRadius: 20,
                    p: 2,
                    fontSize: 14,
                    fontWeight: 600,
                  }}
                  fullWidth
                >
                  Preorder Now
                </Button>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </Drawer>
  );
}

OutletUnavailableDialog.propTypes = {
  onClose: PropTypes.func,
  onRetry: PropTypes.func,
};

OutletUnavailableDialog.defaultProps = {
  onClose: () => {},
  onRetry: () => {},
};

export default OutletUnavailableDialog;
