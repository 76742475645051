import APIService from "./APIService";

export default class MenuService extends APIService {
  static getMenu(params) {
    const queryParameter = this.buildQuery(params);
    return this.get(`/MenuCategoryItemV2${queryParameter}`)
      .then(this.getResponseData)
      .catch(this.getResponseError);
  }
}
