import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  outlets: [],
  isLoading: true,
  error: null,
  name: "",
  data: {},
  tenantId: null,
};
const tenant = createSlice({
  name: "tenant",
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    startLoading: (state, { payload }) => ({
      ...state,
      isLoading: true,
      error: null,
    }),
    endLoading: (state, { payload }) => ({ ...state, isLoading: false }),
    updateTenant: (state, { payload }) => {
      const { outlets, hierarchy } = payload;
      const [tenant] = hierarchy;
      return {
        ...state,
        outlets,
        tenantId: hierarchy.hierarchyId,
        data: {
          ...payload,
          ...tenant,
        },
      };
    },
    updateError: (state, { payload }) => ({ ...state, error: payload }),
    updateTenantId: (state, { payload }) => ({ ...state, tenantId: payload }),
  },
});

export const actions = tenant.actions;

export default tenant;
