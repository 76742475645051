import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// MUI imports
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Card, CardContent, Paper, TextField } from "@mui/material";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";

import { modifiersTransformer } from "@root/util/modifiersTransformer";
import Allergens from "@components/allergens";

// Components
import ItemActionButton from "@components/item-action-button";
import ModifierOverview from "@components/modifiers";
import Currency from "@components/currency";

// Redux
import { useDispatch } from "react-redux";
import { actions as OrderActions } from "@store/order/reducer";

import "./style.css";
import ImageContainer from "@components/image-component";
import { ModifiedGrid } from "./constnats";

function ItemDetail({ item: selectedItem, handleClose, variant, attributes }) {
  const [item, setItem] = useState(null);
  const [errors, setErrors] = useState({});
  const [specialRequirement, setSpecialRequirement] = useState("");
  const [canAddToBasket, setCanAddToBasket] = useState(false);
  const [selectedModifiers, selectModifiers] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedItem) {
      setItem({
        ...selectedItem,
        quantity: selectedItem.quantity ?? 1,
        totalPrice: selectedItem.price,
        modifiedUnitPrice: selectedItem.price ?? 0,
      });

      if (
        variant !== "add" &&
        item?.selectedModifiers &&
        item?.selectedModifiers.length
      ) {
        selectModifiers(item?.selectedModifiers);
      }
    }
  }, [selectedItem]);

  const increaseQuantity = () => {
    let { quantity, totalPrice, modifiedUnitPrice = 0 } = item;
    quantity += 1;
    totalPrice = quantity * modifiedUnitPrice;
    setItem({ ...item, quantity, totalPrice });
  };

  const decreaseQuantity = () => {
    if (item.quantity === 1) {
      return;
    }

    let { quantity, totalPrice, modifiedUnitPrice = 0 } = item;
    quantity -= 1;
    totalPrice = quantity * modifiedUnitPrice;
    setItem({ ...item, quantity, totalPrice });
  };

  if (!item) {
    return null;
  }

  let modifiers = modifiersTransformer(item?.items) || [];

  // Validation modifiers
  const requiredList = modifiers.reduce(
    (acc, modifier) => (modifier.isMandatory ? [...acc, modifier.itemId] : acc),
    []
  );

  const validateModifiers = (formData) => {
    const names = Object.keys(formData);
    const notSelected = requiredList.filter(
      (item) => !names.includes(`${item}`)
    );

    if (notSelected?.length) {
      const x = notSelected.reduce(
        (acc, item) => ({ ...acc, [item]: "Please select at-least one" }),
        {}
      );
      setErrors({ ...x });
      return false;
    } else {
      setErrors({});
    }
    return true;
  };

  // End -> Validation modifiers

  const getCalories = (attributes = []) => {
    const [cal] = attributes.filter(
      (attribute) => attribute.key === "calories"
    );
    return cal?.value;
  };

  // Getting flat array of selected modifiers
  // This will help us in calculating total amount and modifier payload
  // All comparisation are made number to string
  const selectedModifiersIds = Object.keys(selectedModifiers);
  const selectedModifiersDetails = modifiers.reduce((acc, modifier) => {
    if (selectedModifiersIds.includes(`${modifier.itemId}`)) {
      const value = selectedModifiers[modifier.itemId];

      if (!modifier.allowMultiSelect) {
        const [item] = modifier.items.filter((item) => item.itemId == value);
        return [...acc, item];
      } else {
        const item = modifier.items.filter((item) =>
          value.includes(`${item.itemId}`)
        );
        return [...acc, ...item];
      }
    }
    return acc;
  }, []);
  // End -> Getting flat array of selected modifiers

  const getModifierTotal = () => {
    const done = selectedModifiersDetails.map((item) => item.price);
    const cost = done.reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      0
    );
    return cost;
  };
  const modifierTotalAmount = getModifierTotal();

  const addItem = () => {
    const isValid = validateModifiers(selectedModifiers);
    if (!isValid) {
      return;
    }

    const cost = item.modifiedUnitPrice + modifierTotalAmount;
    const modifiedCost = item.quantity * cost;
    dispatch(
      OrderActions.addItem({
        ...item,
        comment: specialRequirement,
        selectedModifiers: selectedModifiersDetails,
        totalPrice: modifiedCost,
        modifierTotalAmount,
        cost,
      })
    );
    handleClose();
  };

  const getTotalPrice = () => {
    let modTotal = getModifierTotal();
    console.log({ modTotal, variant });
    // if (
    //   item?.selectedModifiers &&
    //   item?.selectedModifiers.length &&

    // ) {
    //   modTotal = item?.selectedModifiers.reduce((acc, a) => acc + a.price, 0);
    // }
    const cost = item.modifiedUnitPrice + modTotal;
    console.log(item.modifiedUnitPrice, modTotal, cost);
    return item.quantity * cost;
  };

  return (
    <Box backgroundColor="#F2F2F2" height="100%">
      <Box overflow="auto" height="100%">
        <Grid
          container
          gap={2}
          sx={{ pb: "200px", backgroundColor: "#F2F2F2" }}
        >
          <Grid item xs={12}>
            <Box position="relative" mt="48px">
              <Box position="absolute" top="-38px">
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                  sx={{ zIndex: "tooltip", ml: 1, backgroundColor: "#fff" }}
                >
                  <KeyboardBackspaceRoundedIcon fontSize="large" />
                </IconButton>
              </Box>

              {item.imageUrl && (
                <ImageContainer
                  height={"211px"}
                  borderRadius="0 0 24px 24px"
                  fit="cover"
                  imageUrl={item.imageUrl}
                  altText={item.name}
                  position={"-2.9rem"}
                  opacity={70}
                />
              )}
            </Box>
          </Grid>
          <ModifiedGrid
            title={
              <>
                <Grid item xs={9}>
                  <Typography variant="h3" gutterBottom>
                    {item.name}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subTitle4" align="right">
                    <Currency value={item.price} />
                  </Typography>
                </Grid>
              </>
            }
          >
            {Boolean(
              item.description || item?.transformedAttributes?.allergens?.length
            ) && (
              <Grid item xs={12}>
                {item.description && (
                  <Typography
                    variant="subText5"
                    dangerouslySetInnerHTML={{
                      __html: item.description,
                    }}
                    gutterBottom
                  />
                )}
                {item?.transformedAttributes?.allergens && (
                  <Box display="flex" gap="5px" width="345px" padding="10px 0">
                    <Allergens attributes={item?.transformedAttributes} />
                  </Box>
                )}
                <Divider sx={{ width: "100%" }} />
              </Grid>
            )}
            <Grid item xs={12}>
              {item?.transformedAttributes?.nutritionPrimary && (
                <Typography variant="subHeading5">
                  {item?.transformedAttributes?.nutritionPrimary}
                </Typography>
              )}
              {item?.transformedAttributes?.nutritionSecondary && (
                <Typography variant="subText5" color="#818181">
                  {item?.transformedAttributes?.nutritionSecondary}
                </Typography>
              )}
            </Grid>
          </ModifiedGrid>

          {modifiers?.length > 0 && (
            <ModifierOverview
              modifiers={modifiers}
              setCanAddToBasket={setCanAddToBasket}
              onModifierChange={selectModifiers}
              selectedModifiers={item?.selectedModifiers}
              errors={errors}
              attributes={attributes}
            />
          )}
          {item.allowComments && !attributes.isDigitalMenu && (
            <ModifiedGrid
              title={
                <Typography variant="h3" gutterBottom>
                  Special Requirements
                </Typography>
              }
            >
              <Box mt={3} mb={2} width="100%">
                <TextField
                  placeholder="Special requirement"
                  onChange={({ target: { value } }) =>
                    setSpecialRequirement(value)
                  }
                  name="specialRequirement"
                  variant="standard"
                  inputProps={{ maxlength: attributes?.maxItemCommentChars }}
                  multiline={4}
                  fullWidth
                  sx={{
                    backgroundColor: "#e2e2e2",
                    "& textarea": {
                      height: "200px !important",
                      resize: "none",
                    },
                  }}
                />
              </Box>
            </ModifiedGrid>
          )}
        </Grid>
      </Box>
      {!attributes?.isDigitalMenu && (
        <ItemActionButton
          {...{
            decreaseQuantity,
            increaseQuantity,
            addItem,
            item: { ...item, totalPrice: getTotalPrice() },
          }}
        />
      )}
    </Box>
  );
}

ItemDetail.propTypes = {
  item: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  variant: PropTypes.string,
};

ItemDetail.defaultProps = {
  variant: "add",
};

export default ItemDetail;
