import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { actions as OrderActions } from "@store/order/reducer";

function OrderComment({ value, ...props }) {
  const dispatch = useDispatch();
  const { updateComment } = OrderActions;
  const { comment } = useSelector((state) => state.order);

  const onChange = ({ target: { value } }) => {
    dispatch(updateComment(value));
    props.onChange({ target: { value, name: props.name } });
  };

  return (
    <TextField
      id="standard-basic"
      label="Order Comment"
      variant="standard"
      multiline
      maxRows={6}
      fullWidth
      value={value}
      onChange={onChange}
    />
  );
}

OrderComment.propTypes = {};

export default OrderComment;
