import React from "react";
import PropTypes from "prop-types";
import { Grid, Box } from "@mui/material";

import Payment from "@containers/payment";
import ReviewOrder from "@components/review-order";

import IconButton from "@mui/material/IconButton";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import PaymentGatewaySelector from "@containers/payment-gateway-selector";

import { useHistory } from "react-router-dom";

import { useDispatch } from "react-redux";
import { actions as CheckoutActions } from "@store/checkout/reducer";

import "./styles.css";

function ViewOrder(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const goToMenu = () => {
    dispatch(CheckoutActions.updateContinueToPay(false));
    history.goBack();
  };

  return (
    <Box p={3} pb="120px">
      <Grid container rowSpacing={4}>
        <Grid item xs={12}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={goToMenu}
            aria-label="close"
          >
            <KeyboardBackspaceRoundedIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <ReviewOrder />
        </Grid>
        <Grid item xs={12}>
          <Payment>
            <PaymentGatewaySelector />
          </Payment>
        </Grid>
      </Grid>
    </Box>
  );
}

ViewOrder.propTypes = {};

export default ViewOrder;
