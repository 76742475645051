import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

function CircularIndeterminate({ height }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height,
      }}
    >
      <Stack spacing={2}>
        <Typography align="center">
          <CircularProgress />
        </Typography>
        <Typography variant="h3" align="center">
          Loading
        </Typography>
      </Stack>
    </Box>
  );
}

CircularIndeterminate.prototype = {
  height: PropTypes.string,
};

CircularIndeterminate.defaultProps = {
  height: "100vh",
};

export default CircularIndeterminate;
