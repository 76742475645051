import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, Divider } from "@mui/material";
import { useSelector } from "react-redux";
import Currency from "@components/currency";
import { divide, multiply } from "mathjs";
import { formatToCurrency as formatNumber } from "@root/util/currency";

function ReviewOrder(props) {
  const { tipsAmount } = useSelector((state) => state.order);
  const { orderTotals = {} } = useSelector((state) => state.checkout);
  const { surcharges = [], discounts = [] } = orderTotals;

  const percentage = () => {
    return multiply(divide(tipsAmount, orderTotals?.subtotal), 100);
  };

  return (
    <Grid container rowSpacing={1}>
      <Grid item xs={12}>
        <Typography variant="subTitle3" sx={{ weight: 500, mb: 2 }}>
          Review Order
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="subText5">Subtotal</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="subText5" align="right">
          <Currency value={orderTotals?.subtotal} />
        </Typography>
      </Grid>
      {Boolean(tipsAmount) && (
        <>
          <Grid item xs={6}>
            <Typography variant="subText5" sx={{ color: "#7c7c7c" }}>
              Tip
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="subText5"
              align="right"
              sx={{ color: "#7c7c7c" }}
            >
              <Currency value={tipsAmount} />
            </Typography>
          </Grid>
        </>
      )}
      <Grid item xs={6}>
        <Typography variant="h4">
          <strong>Total</strong>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h4" align="right">
          <strong>
            <Currency value={tipsAmount + orderTotals?.totalPrice} />
          </strong>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
}

ReviewOrder.propTypes = {};

export default ReviewOrder;
