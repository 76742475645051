import React from "react";
import { Grid, Typography } from "@mui/material";

import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";

import MuiTextField from "../text-field";
import StripeInput from "./stripeElements";

import "./style.css";
import { styled } from "@mui/styles";

const TextField = styled(MuiTextField)(({ theme, ...props }) => ({
  "& .MuiFormLabel-root": {
    top: "-27px",
    left: "-1px",
    transform: "none",
    color: "#000 !important",
    fontFamily: "Manrope, Open Sans, Segoe UI, sans-serif",
    fontSize: "14px",
    fontWeight: 500,
  },
  "& .MuiOutlinedInput-root": {
    border: "1px solid #99a3ad",
    borderRadius: "6px",
    padding: "0px",
  },
  "& .MuiInputBase-input": {
    padding: "9px 14px !important",
    fontSize: "14px",
    fontWeight: 500,
    color: "#000",
    height: "20px",
  },
  "& .StripeElement": {
    padding: "10px 14px 8px 14px !important",
    height: "unset !important",
  },
  "& fieldset": {
    display: "none",
  },
}));

const stripeInputStyle = {
  options: {
    cssSrc:
      "https://fonts.googleapis.com/css2?family=Manrope:wght@500&display=swap",
    style: {
      base: {
        fontSize: "14px",
        fontWeight: 700,
        fontFamily: "Manrope, Open Sans, Segoe UI, sans-serif",
      },
    },
  },
};

function CardForm({ updatePaymentDetails, classes, ...props }) {
  const [name, setName] = React.useState("");

  const onNameChange = (cardHolderName) => {
    setName(cardHolderName);
    props.onNameChange(cardHolderName);
  };

  return (
    <Grid container spacing={5}>
      <Grid item xs={12} style={{ marginBottom: 9 }}>
        <TextField
          label="Card number"
          InputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: CardNumberElement,
              ...stripeInputStyle,
            },
          }}
          sx={{ backgroundColor: ({ palette }) => palette.background.paper }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 9 }}>
        <TextField
          label="Cardholder name"
          placeholder="Name"
          fullWidth
          value={name}
          sx={{ backgroundColor: ({ palette }) => palette.background.paper }}
          onChange={({ target: { value } }) => onNameChange(value)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Expiry date"
          sx={{
            backgroundColor: ({ palette }) => palette.background.paper,
            "& .MuiInput-input": { textAlign: "left" },
          }}
          InputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: CardExpiryElement,
              ...stripeInputStyle,
            },
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="CVV / CVC"
          sx={{ backgroundColor: ({ palette }) => palette.background.paper }}
          InputProps={{
            inputComponent: StripeInput,
            inputProps: {
              component: CardCvcElement,
              ...stripeInputStyle,
            },
          }}
          fullWidth
        />
      </Grid>
    </Grid>
  );
}

CardForm.propTypes = {};
export default CardForm;
