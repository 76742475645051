import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { Box, Button, Typography } from "@mui/material";
import RadioModifiers from "@components/radio-modifiers";
import CheckboxModifiers from "@components/checkbox-modifiers";
import { getSelectedModifiers } from "./constants";

import { useFormik } from "formik";
import { ModifiedGrid } from "@components/item-detail/constnats";

function ModifierOverview({
  modifiers,
  errors,
  onModifierChange,
  selectedModifiers,
  attributes,
}) {
  const initialValues = modifiers.reduce((acc, modifier) => {
    const { items, allowMultiSelect, itemId } = modifier;
    const defaults = items.filter((item) => item.isDefault);

    if (!allowMultiSelect && defaults.length) {
      return { ...acc, [itemId]: defaults[0].itemId };
    }
    if (defaults.length && allowMultiSelect) {
      return { ...acc, [itemId]: defaults.map((item) => String(item.itemId)) };
    }
    return acc;
  }, {});

  const onSave = (formData) => {
    const newObject = {};
    for (const [name, value] of Object.entries(formData)) {
      if (value !== null) {
        newObject[name] = value;
      }
    }
    return onModifierChange(newObject);
  };

  const selectedMod = getSelectedModifiers(selectedModifiers, modifiers);

  const formik = useFormik({
    initialValues: { ...initialValues, ...selectedMod },
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: onSave,
  });

  const modifiedLabel = (modifier) => {
    var label = "You may choose";
    if (modifier?.isMandatory) {
      label = "Please choose 1";
    }

    if (modifier.allowMultiSelect) {
      label = ` You may choose up to ${modifier.items.length}`;
    }

    return `${label}`;
  };

  const onChange = (e) => {
    formik.handleChange(e);
    formik.handleSubmit();
  };

  useEffect(() => {
    if (Boolean(initialValues) && Object.keys(initialValues).length) {
      onModifierChange(initialValues);
    }
  }, []);

  return modifiers.map((modifier, index) => (
    <ModifiedGrid
      title={
        <Typography variant="h3" gutterBottom>
          {modifier.name}{" "}
          {modifiedLabel(modifier) ? (
            <Box
              component="span"
              fontSize="14px"
              fontWeight="400"
              color="#818181"
            >
              ( {modifiedLabel(modifier)} )
            </Box>
          ) : null}
        </Typography>
      }
    >
      {modifier?.allowMultiSelect ? (
        <CheckboxModifiers
          modifier={modifier}
          modifiedLabel={modifiedLabel(modifier)}
          onChange={onChange}
          value={formik.values}
          setValue={formik.setFieldValue}
          errors={errors}
          attributes={attributes}
        />
      ) : (
        <RadioModifiers
          modifier={modifier}
          modifiedLabel={modifiedLabel(modifier)}
          onChange={onChange}
          setValue={formik.setFieldValue}
          value={formik.values}
          errors={errors}
          attributes={attributes}
        />
      )}
    </ModifiedGrid>
  ));
}

ModifierOverview.propTypes = {
  modifiers: PropTypes.array.isRequired,
};

export default ModifierOverview;
