import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Currency from "@components/currency";
import { FormGroup } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";

function CheckboxModifier({
  modifier,
  modifiedLabel,
  value,
  errors,
  attributes,
  ...props
}) {
  const checkboxValue = value[modifier.itemId];

  const onChange = ({ name, value }) => {
    if (checkboxValue.includes(String(value))) {
      return props.setValue(
        name,
        checkboxValue.filter((item) => item != value)
      );
    }
    const values = [...new Set([...checkboxValue, String(value)])];
    props.setValue(name, values);
  };

  return (
    <Grid item xs={12}>
      <FormControl error={Boolean(errors[modifier.itemId])}>
        <FormGroup>
          <Grid container>
            {modifier.items.map((item, index) => (
              <Grid
                item
                container
                sx={{
                  padding: "16px 0 0 0",
                  borderBottom:
                    modifier.items.length - 1 === index
                      ? ""
                      : "1px solid #e0e0e0",
                  paddingBottom:
                    modifier.items.length - 1 === index ? 0 : "16px",
                }}
                onClick={() =>
                  onChange({
                    value: item.itemId,
                    name: modifier.itemId,
                  })
                }
              >
                <Grid item xs={10}>
                  <Typography variant="h5">{item.name}</Typography>
                  {item.price !== 0 && (
                    <Typography variant="subTitle5">
                      {item.price < 0 ? "-" : "+"}{" "}
                      <Currency value={Math.abs(item.price)} />
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  xs={2}
                  container
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  {!attributes.isDigitalMenu && (
                    <FormControlLabel
                      value={item.itemId}
                      control={
                        <Checkbox
                          checked={checkboxValue?.includes(`${item.itemId}`)}
                        />
                      }
                      sx={{
                        width: "20px",
                        height: "20px",
                        margin: 0,
                        "& .MuiCheckbox-root": {
                          padding: 0,
                        },
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </FormGroup>
        {errors[modifier.itemId] && (
          <FormHelperText>{errors[modifier.itemId]}</FormHelperText>
        )}
      </FormControl>
    </Grid>
  );
}

CheckboxModifier.propTypes = {};

export default CheckboxModifier;
