import { formatToCurrency } from "@root/util/currency";
import { multiply, add } from "mathjs";

export const backdropStyle = {
  top: "-209px",
  left: "-24px",
  position: "absolute",
  zIndex: 999999,
  width: "100vw",
  height: "100vh",
  background: "#9b9a9a75",
};

export const getTotalPayment = ({ totalPrice, tipsAmount }) =>
  parseInt(
    formatToCurrency(
      multiply(
        add(formatToCurrency(totalPrice), formatToCurrency(tipsAmount)),
        100
      )
    )
  );

export const getTotalAmount = ({ totalPrice, tipsAmount }) =>
  formatToCurrency(
    add(formatToCurrency(totalPrice), formatToCurrency(tipsAmount))
  );
