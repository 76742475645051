import { createSlice } from "@reduxjs/toolkit";
import { normalize, schema } from "normalizr";
const itemEntity = new schema.Entity("item");

const initialState = {
  isLoading: true,
  selectedTipsOption: 0,
  order: null,
  items: [],
  itemsSummary: [],
  itemIds: [],
  totalItemsAmount: 0,
  totalOrderAmount: 0,
  tipsAmount: 0,
  orderFor: {
    type: "",
    value: "",
  },
  error: null,
  comment: "",
  agree: false,
};

const order = createSlice({
  name: "order",
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    startLoading: (state) => ({
      ...state,
      isLoading: true,
      error: null,
    }),
    endLoading: (state) => ({ ...state, isLoading: false }),
    updateComment: (state, { payload }) => ({ ...state, comment: payload }),
    updateAgree: (state, { payload }) => ({ ...state, agree: payload }),
    updateOrder: (state, { payload }) => ({ ...state, order: payload }),
    addItem: (state, { payload }) => {
      const { items, itemIds } = state;
      const newItems = { ...items };
      const newIds = [...itemIds];
      newItems[payload.itemId] = { ...payload };
      newIds.push(payload.id);

      const totalPrice = Object.values(newItems).reduce(
        (acc, item) => acc + item.totalPrice,
        0
      );

      return {
        ...state,
        items: newItems,
        itemsSummary: newItems,
        itemIds: newIds,
        totalItemsAmount: totalPrice,
        totalOrderAmount: totalPrice,
      };
    },
    emptyCart: (state, { payload }) => {
      return { ...state, items: [] };
    },
    deleteItem: (state, { payload }) => {
      const { items, totalItemsAmount, totalOrderAmount } = state;
      const newItems = { ...items };
      if (!Boolean(newItems[payload])) {
        return state;
      }
      const { totalPrice } = newItems[payload];
      delete newItems[payload];
      return {
        ...state,
        items: newItems,
        totalItemsAmount: totalItemsAmount - totalPrice,
        totalOrderAmount: totalOrderAmount - totalPrice,
      };
    },
    updateTotalOrderAmount: (state, { payload }) => {},
    updateItemQuantity: (state, { payload }) => {
      const { items } = state;
      const { id, quantity } = payload;
      const newItem = items[id];
      newItem.quantity = quantity;
      return {
        state,
        items: { ...items, newItem },
      };
    },
    updateTipsAmount: (state, { payload }) => ({
      ...state,
      tipsAmount: payload?.tipsAmount,
      selectedTipsOption: payload?.selectedTipsOption,
    }),
    updateOrderFor: (state, { payload }) => ({
      ...state,
      orderFor: payload,
    }),
  },
});

export const actions = order.actions;

export default order;
