import { ReactComponent as rating1 } from "./rating/1.svg";
import { ReactComponent as ratingGroup } from "./rating/rating-group.svg";
import { ReactComponent as checked } from "./checked.svg";
import { ReactComponent as zigzag } from "./zigzag.svg";
import { ReactComponent as zigzagLight } from "./zigzag-light.svg";
import { ReactComponent as zigzagDisabled } from "./zigzag-disabled.svg";
import { ReactComponent as filter } from "./FilterIcon.svg";
import { ReactComponent as cancel } from "./cancel.svg";

import { ReactComponent as glutenFree } from "./GF.svg";
import { ReactComponent as lactoseFree } from "./LF.svg";
import { ReactComponent as vegetarian } from "./V.svg";
import { ReactComponent as vegan } from "./VG.svg";
import { ReactComponent as shellfishFree } from "./SF.svg";
import { ReactComponent as search } from "./searchIcon.svg";
import { ReactComponent as expandMenu } from "./expandMenu.svg";

const icons = {
  rating1,
  ratingGroup,
  checked,
  zigzag,
  zigzagLight,
  zigzagDisabled,
  filter,
  cancel,
  glutenFree,
  lactoseFree,
  vegetarian,
  vegan,
  shellfishFree,
  search,
  expandMenu,
};

export default icons;
