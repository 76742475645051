import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  session: null,
  error: null,
  sessionToken: null,
};

const session = createSlice({
  name: "session",
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    startLoading: (state, { payload }) => ({
      ...state,
      isLoading: true,
      error: null,
    }),
    endLoading: (state, { payload }) => ({ ...state, isLoading: false }),
    updateSession: (state, { payload }) => ({ ...state, session: payload }),
    updateError: (state, { payload }) => ({ ...state, error: payload }),
  },
});

export const actions = session.actions;

export default session;
