import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid, Typography, TextField, Box } from "@mui/material";
import FloatingButton from "@components/floating-button";
import { useFormik } from "formik";
import * as yup from "yup";
import FeedbackService from "@services/FeedbackService";
import { useSelector } from "react-redux";
import { action as UiActions } from "@store/ui/reducer";
import { useDispatch } from "react-redux";

const emailSchema = yup.object({
  emailAddress: yup.string().required().nullable().label("Email"),
});

function EmailReceipt({ checkout, order }) {
  const [emailIsSent, updateEmailIsSent] = useState(false);

  // All Hooks declared
  const dispatch = useDispatch();
  const { orderDetails = {} } = checkout;
  const { email } = order;
  const { orderId } = orderDetails?.order ?? {};

  const onSave = (formData) => {
    FeedbackService.email({
      OrderId: orderId,
      confirmationEmail: formData.emailAddress,
    }).then((res) =>
      dispatch(UiActions.setSnackBarSuccess("Your receipt has been sent"))
    );
  };

  const formik = useFormik({
    initialValues: { emailAddress: email },
    validationSchema: emailSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: onSave,
  });

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h2" sx={{ fontSize: "18px" }}>
          Enter Email For Receipt
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          placeholder="Email Address"
          onChange={formik.handleChange}
          value={formik.values?.emailAddress}
          name="emailAddress"
          error={Boolean(formik.errors?.emailAddress)}
          disabled={emailIsSent}
          helperText={
            Boolean(formik.errors?.emailAddress)
              ? formik.errors?.emailAddress
              : null
          }
          size="small"
          type="email"
          fullWidth
        ></TextField>
      </Grid>
      <Box sx={{ pt: 2, width: "100%" }}>
        <FloatingButton
          message={emailIsSent ? "Your receipt has been sent!" : null}
          onClick={!emailIsSent ? formik.handleSubmit : null}
          sx={{ mt: 2 }}
        >
          Submit
        </FloatingButton>
      </Box>
    </Grid>
  );
}

EmailReceipt.propTypes = {};

export default EmailReceipt;
